import { Component, OnInit } from '@angular/core';
const { version: appVersion } = require('../../../../package.json')


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public appVersion;
  public copyrightYear;

  constructor() {
    this.appVersion = appVersion
   }

  ngOnInit(): void {
    this.copyrightYear = new Date().getFullYear();
  }

}
