import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DataAPIService } from './dataAPI/data-api.service';

@NgModule({
    declarations: [],
    imports: [HttpClientModule],
    providers: [DataAPIService]
})
export class DataModule { }
