import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BoardTypeModel, changeHistoryDetailsMasterModel, changeHistoryMasterModel, DaysofweekModel, DismissalReviewQueueMasterModel, DismissalreviewqueueModel, EmployeeOnPointRangeVO, EmployeePointRangeMasterModel, EmployeeSecurity, GlobalPropertiesSearchMasterModel, LayoffCodesModel, MasterProfileModel, roleDeleteMasterModel, SaveMasterProfileResponseModel, SearchChangeHistoryMasterModel, SearchchangeHistoryModel, SearchEmployeeSecurityModel, SearchMasterProfileModel, SearchMasterResponseModel, SearchUsersSecurity, SecurityEditRoleMasterModel, SecurityRoleSearchMasterModel, SendToAPDSResponseModel, SendTOAPDSVO, ServiceUnitRangeMasterModel, StepProgressionSetupVO, userDeleteMasterModel } from 'src/app/common/data/dataAPI/data-api.model';
import { Endpoints } from 'src/app/common/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminControlService {
  public searchmasterProfileUrl = '/secure/jas/services/masterProfiles/search';
  public  updatemasterProfileUrl = '/secure/jas/services/masterProfiles/updateProfile';
  public  deletemasterProfileUrl = '/secure/jas/services/masterProfiles/deleteProfile';
  public  masterProfilePdfUrl = '/secure/jas/services/pdf/masterProfilePdf';
  public  globalPropertiesDeleteUrl = '/secure/jas/services/applicationAttributes/delete';
  public  roleSearchUrl = '/secure/jas/services/security/search';
  public  getglobalPropertiesUrl = '/secure/jas/services/applicationAttributes/getByType';
  public  roleGeneratePdfUrl = '/secure/jas/services/pdf/generatePdf';
  public  deleteSecurityRoleUrl = '/secure/jas/services/security/deleteSecurityRole';
  public  securitySearchUsersUrl = '/secure/jas/services/employee/getEmployees';
  public  userSecurityPdfUrl = '/secure/jas/services/pdf/userSecurityPdf';
  public  userDeleteUrl = '/secure/jas/services/employee/deleteEmployee';
  public  searchchangeHistoryUrl = '/secure/jas/services/changeHistorySearch/search';
  public  getchangeHistoryDifferencesUrl = '/secure/jas/services/changeHistorySearch/getDifferences';
  public  changeHistoryUrl = '/secure/jas/services/individualHistory/changeHistory';
  public  editmasterProfileUrl = '/secure/jas/services/masterProfiles/getProfile';
  public  getAllLiteBoardTypesUrl = '/secure/jas/services/boardType/getAllLiteBoardTypes';
  public  getdaysofWeekUrl = '/secure/jas/services/dayOfWeeks/getAll';
  public  savemasterProfileUrl = '/secure/jas/services/masterProfiles/saveProfile';
  public  globalPropertiesNameTypeAheadUrl = '/secure/jas/services/applicationAttributes/attributeNamesTypeAhead';
  public  globalPropertiesTypeUrl = '/secure/jas/services/applicationAttributes/getAllTypes';
  public  getAllSecurityRolesUrl = '/secure/jas/services/security/getAllSecurityRoles';
  public  securityeditRoleUrl = '/secure/jas/services/security/getSecurityRole';
  public  securityRoleTypeAheadsUrl =  '/secure/jas/services/security/getAllSecurityRoles';
  public  securityServiceUnitTypeAheadsUrl = '/secure/jas/services/employee/getAllServiceUnits';
  public  getchangeHistoryDetailsUrl = '/secure/jas/services/changeHistorySearch/getDetails';
  public  dismissalreviewqueueSearchUrl = '/secure/jas/services/dismissalReviewQueue/search';
  public  dismissalReviewQueuePdfGenerationUrl = '/secure/jas/services/pdf/dissmissalReviewQueuePdf';
  public  sendToAPDSUrl =  '/secure/jas/services/employeeDiscipline/sendToDisciplinarySystem';
  public  dismissalreviewqueueDeleteUrl = '/secure/jas/services/dismissalReviewQueue/delete';
  public  intiatePointGuageUrl = '/secure/jas/services/monitor-attendance/initiatePointsGuage';
  public  attendanceMonitorPdfUrl =  '/secure/jas/services/pdf/monitor-attendancePdf';
  public  profileTypeAheadsUrl =  '/secure/jas/services/masterProfiles/getAll';
  public  copymasterProfileUrl = '/secure/jas/services/masterProfiles/copyProfile';
 
  public  getlayoffCodesUrl = '/secure/jas/services/statusCodes/byType';
  public  securityRegionTypeAheadsUrl = '/secure/jas/services/employee/getAllRegions';
  public  employeeTypeAheadsUrl = '/secure/jas/services/employee/getEmployeeNameAndId';
  public  serviceunitBarUrl =  '/secure/jas/services/monitor-attendance/serviceUnits';
  public  homeLabelUrl = '/secure/jas/services/monitor-attendance/hasDismissalReview';
  public  pointRangeBarServiceUrl = '/secure/jas/services/home/pointRange';
  POST_HOME_CHANGE_DATA=`post/home-data`;
  public  districtDetailsUrl = '/secure/jas/services/home/districtDetails';
  public  craftGroupDetailsUrl = '/secure/jas/services/home/craftGroupDetails';
  POST_USER_SECURITY_DATA=`/post/user-data`
   public static securitySearchUsers ='/secure/jas/services/employee/getEmployees';



  constructor(private httpClient:HttpClient) { }

  public searchmasterProfile = (row: SearchMasterProfileModel): Observable<SearchMasterResponseModel> => {
    let endpoint = `${Endpoints.searchmasterProfile}?profileId=${row.profileId}&effectiveDate=${row.effectiveDate}&expiryDate=${row.expiryDate}&includeInactive=${row.status}`;

   return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let searchresults: SearchMasterResponseModel = new SearchMasterResponseModel();
      searchresults.data = response.data;
      searchresults.result = response.result;
      searchresults.messages = response.messages;
      return searchresults
    }))
  }
  
  public updatemasterProfile = (payload): Observable<SaveMasterProfileResponseModel> => {
    return this.httpClient.post<any>(this.updatemasterProfileUrl, payload).pipe();
  }

  public deletemasterProfile = (oid: number): Observable<any> => {
    let endpoint = `${this.deletemasterProfileUrl}?profileOid=${oid}`;
    return this.httpClient.post<any>(endpoint, {});
  }

  public getMasterProfilePdf = (oid: number): Observable<Blob> => {
    let endpoint = `${Endpoints.masterProfilePdf}?profileOid=${oid}`;
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };

    return this.httpClient.get<Blob>(endpoint, httpOptions);
  }

  public globalPropertiesDelete = (oid: number): Observable<any> => {
    let endpoint = `${this.globalPropertiesDeleteUrl}?oid=${oid}`;
    return this.httpClient.post<any>(endpoint, {});
  }
  public roleSearch = (roleName: string, description: string): Observable<SecurityRoleSearchMasterModel> => {
    let endpoint = `${Endpoints.roleSearch}?roleName=${roleName}&description=${description}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let roleSearchResults: SecurityRoleSearchMasterModel = new SecurityRoleSearchMasterModel();
      roleSearchResults.data = response.data;
      roleSearchResults.result = response.result;
      roleSearchResults.messgaes = response.messages;
      return roleSearchResults;
    }))
  }
  public getglobalProperties = (type: string): Observable<any> => {
    let endpoint = Endpoints.getglobalProperties;
    return this.httpClient.post<any>(endpoint,{"type":type}).pipe(map(response => {
      let getglobalPropertiesResults = response.data;
      return getglobalPropertiesResults;
    }))
  }

  public roleGeneratePdf = (roleName: string, description: string): Observable<Blob> => {

    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(this.roleGeneratePdfUrl, httpOptions);
  }
  public deleteSecurityRole = (roleOid: number): Observable<roleDeleteMasterModel> => {
    let endpoint = `${this.deleteSecurityRoleUrl}?roleOid=${roleOid}`;
    return this.httpClient.post<any>(endpoint, {}).pipe(map(response => {
      let deleteSecurityRoleResults: roleDeleteMasterModel = new roleDeleteMasterModel();
      deleteSecurityRoleResults.data = response.data;
      deleteSecurityRoleResults.messages = response.messages;
      deleteSecurityRoleResults.result = response.result;
      return deleteSecurityRoleResults;
    }));
  }
  public securitySearchUsers = (row: SearchUsersSecurity): Observable<SearchEmployeeSecurityModel> => {
    return this.httpClient.post<any>(this.securitySearchUsersUrl,row).pipe(map(response => {
      let searchResults: SearchEmployeeSecurityModel = new SearchEmployeeSecurityModel();
      searchResults.data = response.data;
      searchResults.result = response.result;
      searchResults.messages = response.messages;
      return searchResults
    }))
  }
  public securitySearchUsersPdf = (row: SearchUsersSecurity): Observable<Blob> => {

    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(this.userSecurityPdfUrl, httpOptions);
  }
  public userDelete = (employeeOid: number): Observable<userDeleteMasterModel> => {
    let endpoint = `${Endpoints.userDeleteUrl}?employeeOid=${employeeOid}`


    return this.httpClient.post<any>(endpoint, {}).pipe(map(response => {
      let userDeleteResults: userDeleteMasterModel = new userDeleteMasterModel();
      userDeleteResults.result = response.result;
      userDeleteResults.messages = response.messages;
      userDeleteResults.data = response.data;
      return userDeleteResults;
    }))
  }
  public searchchangeHistory = (row: SearchchangeHistoryModel): Observable<SearchChangeHistoryMasterModel> => {
    let endpoint = `${Endpoints.searchchangeHistory}?screenName=${row.screenName!=null ? row.screenName : '' }&fromDate=${row.fromDate}&toDate=${row.toDate}&updatedBy=${row.updatedBy}&bussinessAction=${row.bussinessAction}&action=${row.action}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let changehistorySearchResults: SearchChangeHistoryMasterModel = new SearchChangeHistoryMasterModel();
      changehistorySearchResults.data = response.data;
      changehistorySearchResults.result = response.result;
      changehistorySearchResults.messages = response.messages;
      return changehistorySearchResults;
    }))
  }
  public getchangeHistoryDifferences = (changeHistoryOId: number): Observable<any> => {
    return this.httpClient.get<any>(this.getchangeHistoryDifferencesUrl +'?changeHistoryOId=' + changeHistoryOId ).pipe(map(response => {
      let changehistorydifferencesResults = response.data;
      return changehistorydifferencesResults;
    }))
  }
  public changeHistory = (employeeId: string): Observable<changeHistoryMasterModel> => {
    return this.httpClient.get<any>(this.changeHistoryUrl).pipe(map(response => {
      let changeHistoryResults: changeHistoryMasterModel = new changeHistoryMasterModel()
      changeHistoryResults.data = response.data;
      changeHistoryResults.messages = response.messages;
      changeHistoryResults.result = response.result;
      return changeHistoryResults;
    }));
  }
 
  public getMasterProfile = (oid: number): Observable<MasterProfileModel> => {
     let endpoint = `${Endpoints.editmasterProfile}?profileOid=${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let editprofileresponse = response.data;
      return editprofileresponse;
    }))
  }
  public getBoardTypes = (): Observable<BoardTypeModel[]> => {
    let endpoint = Endpoints.getAllLiteBoardTypes;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let boardtypes: BoardTypeModel[] = response.data;
      return boardtypes;
    }))
  }
  public getdaysofWeek = (): Observable<DaysofweekModel[]> => {
   
    return this.httpClient.get<any>(this.getdaysofWeekUrl).pipe(map(response => {
      let daysofWeeks: DaysofweekModel[] = response.data;
      return daysofWeeks
    }))
  }
  public savemasterProfile = (payload): Observable<SaveMasterProfileResponseModel> => {
    return this.httpClient.post<any>(this.savemasterProfileUrl, payload).pipe(map(response => {
      let saveResults: SaveMasterProfileResponseModel = new SaveMasterProfileResponseModel();
      saveResults.result = response.result;
      saveResults.messages = response.messages;
      return saveResults;
    }));
  }
  public globalPropertiesNameTypeAhead = (name: string): Observable<any> => {
    return this.httpClient.get<any>(this.globalPropertiesNameTypeAheadUrl + '?name=' + name).pipe(map(response => {
      let globalPropertiesNameTypeAheadResults = response.data;
      return globalPropertiesNameTypeAheadResults;
    }))
  }
  public globalPropertiesTypeTypeAhead = (): Observable<any> => {
    return this.httpClient.get<any>(this.globalPropertiesTypeUrl).pipe(map(response => {
      let globalPropertiesTypeResults = response.data;
      return globalPropertiesTypeResults;
    }))
  }
  public getAllSecurityRoles = (): Observable<any> => {
    return this.httpClient.get<any>(this.getAllSecurityRolesUrl).pipe(map(response => {
      let getAllSecurityRolesResults = response.data;
      return getAllSecurityRolesResults;
    }))
  }
  public securityeditRole = (oid: number): Observable<SecurityEditRoleMasterModel> => {
    let endpoint = `${this.securityeditRoleUrl}?roleOid=${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let securityeditRoleresults: SecurityEditRoleMasterModel = new SecurityEditRoleMasterModel();
      securityeditRoleresults.data = response.data;
      securityeditRoleresults.result = response.result;
      securityeditRoleresults.messages = response.messages;
      return securityeditRoleresults;
    }))
  }
  public securityRoleTypeAheads = (): Observable<any> => {
    return this.httpClient.get<any>(this.securityRoleTypeAheadsUrl).pipe(map(response => {
      let roletypeaheadresponse: any = response.data;
      return roletypeaheadresponse;
    }))
  }
  public securityServiceUnitTypeAheads = (): Observable<any> => {
    let endpoint = Endpoints.securityServiceUnitTypeAheads;

    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceunittypeaheadresponse: any = response.data;
      return serviceunittypeaheadresponse;
    }))
  }
  public securityDistinctServiceUnitTypeAheads = (): Observable<any> => {
    let endpoint = Endpoints.securityDistinctServiceUnitTypeAheads;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceunittypeaheadresponse: any = response.data;
      return serviceunittypeaheadresponse;
    }))
  }
  public getchangeHistoryDetails = (changeHistoryOId: number): Observable<changeHistoryDetailsMasterModel> => {
    return this.httpClient.get<any>(this.getchangeHistoryDetailsUrl).pipe(map(response => {
      let changehistorydetailsResults: changeHistoryDetailsMasterModel = new changeHistoryDetailsMasterModel();
      changehistorydetailsResults.data = response.data;
      changehistorydetailsResults.result = response.result;
      changehistorydetailsResults.messages = response.messages;
      return changehistorydetailsResults
    }))
  }
  public dismissalreviewqueueSearch = (body:any): Observable<DismissalreviewqueueModel> => {

    return this.httpClient.post<any>(this.dismissalreviewqueueSearchUrl,body).pipe(map(response => {
      let dismissalreviewqueuesearchResults: DismissalreviewqueueModel = new DismissalreviewqueueModel;
      dismissalreviewqueuesearchResults.data = response.data;
      dismissalreviewqueuesearchResults.messages = response.messages;
      dismissalreviewqueuesearchResults.result = response.result;
      return dismissalreviewqueuesearchResults;
    }))
  }
  public dismissalreviewqueuePdf = (employeeId: number, region: string, serviceUnit: number): Observable<Blob> => {

    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(this.dismissalReviewQueuePdfGenerationUrl, httpOptions);
  }
  public sendToAPDS = (selectedApdsRecords: SendTOAPDSVO[]): Observable<SendToAPDSResponseModel> => {
    return this.httpClient.post<any>(this.sendToAPDSUrl,selectedApdsRecords).pipe(map(response => {
      let sendToAPDSResponseModel: SendToAPDSResponseModel = new SendToAPDSResponseModel();
      sendToAPDSResponseModel.data = response.data;
      sendToAPDSResponseModel.messages = response.messages;
      sendToAPDSResponseModel.result = response.result;
      return sendToAPDSResponseModel;
    }))
  }
  
  public dismissalReviewDelete = (oid: number): Observable<any> => {
    return this.httpClient.post<any>(this.dismissalreviewqueueDeleteUrl, {});
  }
  
  public intiatePointGuage = (): Observable<StepProgressionSetupVO> => {
    return this.httpClient.get<any>(this.intiatePointGuageUrl).pipe(map(response => {
      let StepProgressionSetupVO = response.data;
      return StepProgressionSetupVO;
    }))
  }
  public getAttendanceMonitorPdf = (): Observable<Blob> => {
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(this.attendanceMonitorPdfUrl, httpOptions);
  }
  public copymasterProfile = (oid: number): Observable<MasterProfileModel> => {
    return this.httpClient.get<any>(this.copymasterProfileUrl).pipe(map(response => {
      let copyprofileresponse: MasterProfileModel = response.data;
      return copyprofileresponse;
    }))
  }
  public getlayoffCodes = (): Observable<LayoffCodesModel[]> => {
    
    return this.httpClient.get<any>(this.getlayoffCodesUrl).pipe(map(response => {
      let layoffCodes: LayoffCodesModel[] = response.data;
      return layoffCodes;
    }))
  }
  public securityRegionTypeAheads = (): Observable<any> => {
    let endpoint = Endpoints.securityRegionTypeAheads;

    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let regiontypeaheadresponse: any = response.data;
      return regiontypeaheadresponse;
    }))
  }
  public nonTEYEmployeeTypeAheads = (startingWith: string, isTey: boolean): Observable<any> => {
    let endpoint = `${this.employeeTypeAheadsUrl}?startingWith=${startingWith}&nonTeyFlag=${isTey}`;

    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let typeaheadresponse: any = response.data;
      return typeaheadresponse;
    }))
  }


  public profileTypeAheads = (): Observable<any> => {
    return this.httpClient.get<any>(this.profileTypeAheadsUrl).pipe(map(response => {
      let profileTypeAheads: any = response.data;
      return profileTypeAheads;
    }))
  }
  public employeeTypeAheads = (startingWith: string): Observable<any> => {
    let endpoint = `${this.employeeTypeAheadsUrl}?startingWith=${startingWith}`;

    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let typeaheadresponse: any = response.data;
      return typeaheadresponse;
    }))
  }
  public globalPropertiesSearch = (name: string, type: string): Observable<GlobalPropertiesSearchMasterModel> => {
    let endpoint = Endpoints.globalPropertiesSearch;
    return this.httpClient.post<any>(endpoint,{"name":name,"type":type}).pipe(map(response => {
      let globalPropertiesSearchResults = new GlobalPropertiesSearchMasterModel();
      globalPropertiesSearchResults.data = response.data;
      globalPropertiesSearchResults.messages = response.messages;
      globalPropertiesSearchResults.result = response.result;
      return globalPropertiesSearchResults;
    }))
  }
  public serviceunitBar = (body:any): Observable<ServiceUnitRangeMasterModel> => {
    // let endpoint = `${Endpoints.serviceunitBar}?pointRangeList=${pointRangeVo}&regionsList=${regionList}&boardTypeOIds=${boardTypeOIds}`;
    // endpoint = endpoint.replace("+", "%2B");boardTypeOIds
    return this.httpClient.post<any>(this.serviceunitBarUrl,body).pipe(map(response => {
      let serviceunitBarResults: ServiceUnitRangeMasterModel = new ServiceUnitRangeMasterModel();
      serviceunitBarResults.data = response.data;
      serviceunitBarResults.messages = response.messages;
      serviceunitBarResults.result = response.result;
      return serviceunitBarResults;
    }))
  }
  public homeLabel = (): Observable<any> => {
    return this.httpClient.get<any>(this.homeLabelUrl).pipe(map(response => {
      let homeLabelResult = response.data;
      return homeLabelResult;
    }));
  }
  public pointRangeBarService = (body: any): Observable<EmployeePointRangeMasterModel> => {
    let endpoint = `${Endpoints.pointRangeBarService}`;
    endpoint = endpoint.replace("+", "%2B");
    return this.httpClient.post<any>(this.pointRangeBarServiceUrl, body).pipe(map(response => {
      let pointRangeBarServiceResults: EmployeePointRangeMasterModel = new EmployeePointRangeMasterModel();
      pointRangeBarServiceResults.data = <EmployeeOnPointRangeVO>response.data.employeeCountOfRangeMap;
      pointRangeBarServiceResults.result = response.result;
      pointRangeBarServiceResults.messages = response.messages;
      return pointRangeBarServiceResults;
    }))
  }

  public getResults = () => {
    return this.httpClient.post<any>(this.getglobalPropertiesUrl,{"type":"test"}).pipe(map(response => {
      let getglobalPropertiesResults = response.data;
      return getglobalPropertiesResults;
    }))
  }
  public getMissedcallCodes = () => {
    return this.httpClient.post<any>(this.getglobalPropertiesUrl,{"type":"test"}).pipe(map(response => {
      let getglobalPropertiesResults = response.data;
      return getglobalPropertiesResults;
    }))
  }
  postHomeChangeData(body:any){
    return this.httpClient.post(this.pointRangeBarServiceUrl , body)
  }
  postDistrictDetails(body:any){
    return this.httpClient.post<any>(this.districtDetailsUrl,body)
  }
  postCraftGroupDetails(body:any){
    return this.httpClient.post<any>(this.craftGroupDetailsUrl, body)
  }
  postUserSecurityData(body:any){
    return this.httpClient.post<any>(this.POST_USER_SECURITY_DATA,body)
  }

  public deleteUserSecurityProfile = (payload: EmployeeSecurity): Observable<any> => {
    let endpoint = `${Endpoints.deleteEmployeeOrCraftProfile}`;
    return this.httpClient.post<any>(endpoint,payload);
  }

}
