import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CamsCommonModule } from '../common/camsCommon.module';
import { InputTextModule } from 'primeng/inputtext';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { MatListModule, MatNativeDateModule, MatSelectModule } from '@angular/material';
import { FullCalendarModule } from 'ng-fullcalendar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SideNavComponent } from './side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {UpCalendarComponent} from './up-calendar/up-calendar.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../interceptors/jwt.interceptor';
import { CraftComponent } from './filters/components/craft/craft.component';
import { PointRangeComponent } from './filters/components/point-range/point-range.component';
import { DistrictComponent } from './filters/components/district/district.component';
import { EmployeeTypeComponent } from './filters/components/employee-type/employee-type.component';
import { SubDistrictComponent } from './filters/components/sub-district/sub-district.component';
import { DataTableComponent } from './data-table/data-table.component';
import { PickListComponent } from './pick-list/pick-list.component';
import { AddGlobalAttributesComponent } from '../dashboard/admin-control/global-attributes/add-global-attributes/add-global-attributes.component';
import { GlobalPropertiesFormComponent } from '../dashboard/admin-control/global-properties/global-properties-form/global-properties-form.component';
import { GlobalPropertiesAddSharedComponent } from './global-properties-add-shared/global-properties-add-shared.component';
import { TrainmastercodeComponent } from './filters/components/trainmastercode/trainmastercode.component';
import { SupervisorComponent } from './filters/components/supervisor/supervisor.component';
import { RegionComponent } from './filters/components/region/region.component';
import { EmployeeComponent } from './employee/employee.component';
import { ServiceUnitComponent } from './filters/components/service-unit/service-unit.component';
import { RoleComponent } from './filters/components/role/role.component';

@NgModule({
  declarations: [
    UpCalendarComponent,
    PointRangeComponent,
    DistrictComponent,
    PickListComponent,
    CraftComponent,
    EmployeeTypeComponent,
    SubDistrictComponent,
    DataTableComponent,
    GlobalPropertiesAddSharedComponent,
    TrainmastercodeComponent,
    SupervisorComponent,
    RegionComponent,
    EmployeeComponent,
    ServiceUnitComponent,
    RoleComponent
  ],
  imports: [
    CamsCommonModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    InputTextModule,
    DragulaModule,
    MatListModule,
    MatSelectModule,
    MatNativeDateModule,
    CalendarModule,
    FullCalendarModule,
    ScrollingModule
  ],
  exports: [
    UpCalendarComponent,
    PointRangeComponent,
    PickListComponent,
    DistrictComponent,
    CraftComponent,
    EmployeeTypeComponent,
    SubDistrictComponent,
    TrainmastercodeComponent,
    DataTableComponent,
    SupervisorComponent,
    RegionComponent,
    GlobalPropertiesAddSharedComponent,
    EmployeeComponent,
    ServiceUnitComponent,
    RoleComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
  ]
})
export class SharedModule { }
