import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExporttoexcelService {

  constructor() { }

  public exportAsExcelFile(json: any, excelFileName: string): void {
    console.log(json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    let workbook = XLSX.utils.book_new();
    workbook.SheetNames.push(excelFileName);
    workbook.Sheets[excelFileName] = worksheet;
   // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }



  public exportAsPdfFile(json: any, pdfFileName: string): void {
    console.log(json);
    
  }
  public saveAsPdfFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  
  // public generateExcelTemplateForDynamicScreen(json: any, fileName: string) {
  //   let aoa = [[]];
  //   this._generateDataByGroup(metadata.grouping, aoa);
  //   this._generateExcel(aoa, fileName + '.xlsx');
  //   console.log('aoa', aoa)
  //   return aoa;
  // }
}
