import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @Input('columnsList') columnsList: any = [];
  @Input('tableData') tableData: any = [];
  @Input('tableName') tableName: any;
  @Input('dataLength') dataLength: any;
  @Input('pageNo') pageNo: any;
  @Input('pageSize') pageSize: any;
  @Output() paginationChange = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableName) {
      this.tableName = changes.tableName.currentValue;
    }
    console.log("TableName ", this.tableName)
  }


  getPageData(pageNo: number) {
    this.pageNo = pageNo;
    let data = {
      pageNo: this.pageNo,
      pageSize: this.pageSize
    }
    this.paginationChange.emit(data);
  }

  changepageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNo = 1;
    let data = {
      pageNo: this.pageNo,
      pageSize: this.pageSize
    }
    this.paginationChange.emit(data);
  }




}
