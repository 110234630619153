import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { AdminControlService } from 'src/app/dashboard/admin-control/admin-control.service';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';



@Component({
  selector: 'app-service-unit',
  templateUrl: './service-unit.component.html',
  styleUrls: ['./service-unit.component.scss']
})
export class ServiceUnitComponent implements OnInit {
  messages: any[] = [];
  Search = new FormControl();
  subscription: Subscription;
  @Output() subsharedSharedChange = new EventEmitter<any>();
  @Input() subserviceValue: string;
  public serviceUnits = [];
  serviceUnitTemplate = `<span>{{=result.serviceUnitName}}`;
  serviceUnitItems: Observable<string[]>;
  selectedServiceUnit: number = null;
  public serviceunitSelection:string;
  public serviceUnitSelected: number;
  public serviceunittypeaheadresponse:any = [];
  

  constructor( private apiservice:AdminControlService,private apisService:ReportsService) { }

  ngOnInit(): void {
    this.serviceunitresetData()
    this.apiservice.securityServiceUnitTypeAheads().subscribe(response => {
      this.serviceunittypeaheadresponse = response;
    })
 
   
  }
  selectServiceUnit(event: CustomEvent) {
    event.detail.formatInput(item => item.serviceUnitName);
    this.selectedServiceUnit = event.detail.item.serviceUnitOid;
    this.subsharedSharedChange.emit(this.selectedServiceUnit)
  
  
  }
  public onServiceUnitChange = (value) => {
    if (value.target.value.length == 0){
      this.selectedServiceUnit = null;
    }
  }
  changeValue(event: Event) {
    
    this.filter((event.target as HTMLInputElement).value);
}
private filter(input: string = '') {
  this.serviceUnitItems = this.serviceunittypeaheadresponse.filter(i => i.serviceUnitName.toLowerCase().match(input.toLowerCase()));
 
}


public getAllServiceUnits = () =>{
  this.apiservice.securityServiceUnitTypeAheads().subscribe(response => {
    this.serviceUnits = response;
  });
}
serviceunitresetData(){
  this.subscription = this.apisService.onMessage().subscribe(message => {
    if (message) {
        this.Search.reset();
    } 
});
}

}
