import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {  debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';

import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-employee-type',
  templateUrl: './employee-type.component.html',
  styleUrls: ['./employee-type.component.scss']
})
export class EmployeeTypeComponent implements OnInit {
  @Output () employeeChangeValue = new EventEmitter<any>();
  messages: any[] = [];
  subscription: Subscription;
  @Input() employeeSharedtype: string;
  employeeTypeItems: Observable<string[]>;
  public selectEmployee : string;
  employeeChange = new FormControl(["all"]);
  employeeTemplate = `<span>{{=result.type}}</span>`;
  iconSearch = new FormControl();
  public employeeTypeaheadresponse:any = [];
  employeeTypeRange:any
  constructor(private employeeservice:FiltersService, private apisService:ReportsService) { }

  ngOnInit(): void {
    this.employeeresetData();
    this.employeeTypeItems = this.iconSearch.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100)
    );
    this.getEmployeeTypeData()
  }

  getEmployeeTypeData(){
    this.employeeservice.getEmployeeData().subscribe((response: any) => {
      this.employeeTypeaheadresponse = response;
      console.log('value',response);
      
      let employeeType = localStorage.getItem('employeeType');
      if(this.employeeSharedtype){
		         employeeType = this.employeeSharedtype;
		      }
      if(employeeType) {
       const index =  this.employeeTypeaheadresponse.findIndex((x: any) => x.type == employeeType);
       if(index != -1) {
        this.employeeTypeRange = this.employeeTypeaheadresponse[index].type;
        this.emitValue(this.employeeTypeaheadresponse[index].type)
       }
      }
    }, error => {

    })
  }

  emitValue(value: any) {
    this.employeeChangeValue.emit(value);
  }
 

  employeeData(event: CustomEvent){
    event.detail.formatInput(item => item.type);
    this.selectEmployee = event.detail.item.type
    this.employeeChangeValue.emit(this.selectEmployee);
  }
  changeValue(event: Event) {
    this.filter((event.target as HTMLInputElement).value);
    if(!event['data']) {
      this.employeeChangeValue.emit(null)
    }
  }
  private filter(input: string = '') {
    this.employeeTypeItems = this.employeeTypeaheadresponse.filter(i => i.type.toLowerCase().match(input.toLowerCase()));
  }
  employeeresetData(){
    this.subscription = this.apisService.onMessage().subscribe(message => {
      if (message) {
          this.iconSearch.reset();
      } 
  });
  }


}
