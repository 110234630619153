import { Location } from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'rxjs/util/isNumeric';
import { ApplicationAttributeVO, MessageObject } from 'src/app/common/data/dataAPI/data-api.model';
import { DataAPIService } from 'src/app/common/data/dataAPI/data-api.service';
import { ScreenNames } from 'src/app/common/screenNames';
import { AuthorizationUtilService } from 'src/app/common/util/authorization.util.service';

@Component({
  selector: 'app-global-properties-add-shared',
  templateUrl: './global-properties-add-shared.component.html',
  styleUrls: ['./global-properties-add-shared.component.scss']
})
export class GlobalPropertiesAddSharedComponent implements OnInit {
  public globalPropertiesSaveDetails: ApplicationAttributeVO = new ApplicationAttributeVO;
  public messageObject = new MessageObject();
  public editMode = false;
  public globalPropertiesSaveResults: ApplicationAttributeVO;
  showPointAdjustmentError: boolean = false;
  public pointRangeResults: any;
  public ScreenNames = ScreenNames;
  public type: string = '';
  public boardType: string = '';
  public isRoute: boolean;
  public pointAdjustment: string = '';
  public typeCode: string ='';
  @Input() isTypeFieldDisabled: boolean;

  constructor(private apiservice: DataAPIService,private location:Location, private activatedRoute: ActivatedRoute, private router: Router, public authUtil: AuthorizationUtilService) { }

  ngOnInit(): void {
    this.isRoute = window.location.href.includes("attributes-add") ? true :  false;
    console.log("GLobalProperties.type ",this.globalPropertiesSaveDetails.type);
    this.activatedRoute.params.subscribe(params => {
      this.typeCode = params.type;
      if (params.oid) {
        this.editMode = true;
        this.apiservice.getglobalPropertiesEdit(params.oid).subscribe(response => {
          this.globalPropertiesSaveDetails = response.data;
          this.globalPropertiesSaveDetails.edit = true;
          console.log("bobby",this.globalPropertiesSaveDetails)
        });
      }
      if(params.gtype) {
        this.globalPropertiesSaveDetails.edit  = true;
        this.globalPropertiesSaveDetails.type = params.gtype;
      }
    });

    if(this.typeCode){
      this.globalPropertiesSaveDetails.type = this.typeCode;

    }
    console.log("After GLobalProperties.type ",this.globalPropertiesSaveDetails.type);
  }
  
  public onClickofSave = () => {
    if(this.globalPropertiesSaveDetails.type == 'Point Adjustments' && 
    this.globalPropertiesSaveDetails.value != undefined && this.globalPropertiesSaveDetails.value != ""){
        if(!isNumeric(this.globalPropertiesSaveDetails.value)){
          this.showPointAdjustmentError = true;
        }
    }
    if(!this.showPointAdjustmentError){
      if (this.editMode ) {
        this.apiservice.globalPropertiesUpdate(this.globalPropertiesSaveDetails).subscribe(response => {
          this.globalPropertiesSaveResults = response.data;
          if (response.messages) {
            this.messageObject.systemMessages = response.messages;
          }
          if (response.result) {
            this.messageObject.type = response.result.toLowerCase();
          }
          window.scrollTo(0, 0);
        });
      }
      else {
        this.apiservice.globalPropertiesSave(this.globalPropertiesSaveDetails).subscribe(response => {
          this.globalPropertiesSaveResults = response.data;
          if (response.messages) {
            this.messageObject.systemMessages = response.messages;
          }
          if (response.result) {
            this.messageObject.type = response.result.toLowerCase();
          }
          window.scrollTo(0, 0);
  
        });
      }
    }
    
  };
  // public onCancel = () => {
  //   if(this.typeCode) {
  //     this.router.navigate(['/dashboard/admin-control/global-properties']);
  //   }
  //   else {
  //     this.router.navigate(['/dashboard/admin-control/application-attributes']);
  //   }
  // }
  public onCancel = () =>{
    this.location.back();
   
   
    
  }

}
