import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {  debounceTime, filter, map, switchMap } from 'rxjs/operators';

import { FiltersService } from '../../services/filters.service';
import { AdminControlService } from 'src/app/dashboard/admin-control/admin-control.service';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  @Output () roleChangeValue = new EventEmitter<any>();
  @Input() roleSharedtype: string;
  @Input() sharedRole: any;
  @Output() sharedRoleChange =  new EventEmitter<any>();
  role: Observable<string[]>;
  public selectrole : string;
  roleRange:any;
  messages: any[] = [];
  subscription: Subscription;
  @Input() isMultiple: boolean;
  roleChange = new FormControl(["all"]);
  iconSearch = new FormControl(["all"]);
  roleItems: Observable<string[]>;
  roleTemplate = `<span>{{=result.roleName}}`;
  selectedrole: number = null;
  public roleSelection:string;
  public roletypeaheadresponse:any = [];
  selectedRole: number = null;
  constructor(private roleservice:FiltersService, private apiService: AdminControlService,private apisService:ReportsService) { }

  ngOnInit(): void {
    this.roleresetData()
    this.role = this.iconSearch.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100),
    );
    this.getroleTypeData()
  }

  getroleTypeData(){
    this.apiService.securityRoleTypeAheads().subscribe(response => {
      this.roletypeaheadresponse = response;
    })
  }

  private filter(input: string = '') {
    this.roleItems = this.roletypeaheadresponse.filter(i => i.roleName !== null && i.roleName.toLowerCase().match(input.toLowerCase()));
  }
 
  emitValue(value:any){
   this.roleChangeValue.emit(value)
  }
  roleData(event: CustomEvent){
    event.detail.formatInput(item => item.Name);
    this.selectrole = event.detail.item.tName
  }
  selectRole(event: CustomEvent) {
    event.detail.formatInput(item => item.roleName);
    this.selectedRole = event.detail.item.roleOid;
    this.sharedRoleChange.emit(this.selectedRole);
  }

   changeValue(event: Event) {

    this.filter((event.target as HTMLInputElement).value);
    if(!event['data']){
      this.sharedRoleChange.emit(null)
    }
  }
  roleresetData(){
    this.subscription = this.apisService.onMessage().subscribe(message => {
      if (message) {
          this.iconSearch.reset();
      } 
  });
  }


}