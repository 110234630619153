import {Component, ViewEncapsulation} from '@angular/core';
import * as _ from 'lodash';
import { SharedDataService } from './common/util/sharedData.service';
import { Router } from '@angular/router';
import { UserModel } from './common/models/UserModel';
import { AuthorizationUtilService } from './common/util/authorization.util.service';
import { DataAPIService } from './common/data/dataAPI/data-api.service';
import { MessageObject, UserCapabilitiesModel, SecurityRolesVO } from './common/data/dataAPI/data-api.model';
import { ScreenNames } from './common/screenNames';
import { environment } from 'src/environments/environment';
import { ReportsService } from './dashboard/reports/reports.service';
// import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CAMS';
  token: string;
  isModalOpen = false;
  role: string;
  hideUi = false;
  public roleSwitchCapabilitiesResults: any;
  public userCapabilitiesResults: UserCapabilitiesModel = new UserCapabilitiesModel();
  public messageObject = new MessageObject();
  public screenNames = ScreenNames;
  constructor(public sharedDataService: SharedDataService, private router: Router, private apiservice: DataAPIService, public authUtil: AuthorizationUtilService, private reportService: ReportsService) {
    if (environment.isUp) {
      sessionStorage.setItem('access-token', 'true');
      this.apiservice.upAuthenticate().subscribe(response => {
        if (response.data === 'OK') {
          this.hideUi = false;
          if (sessionStorage.getItem('access-token')) {
            this.token = sessionStorage.getItem('access-token');
            this.sharedDataService.allRoles = JSON.parse(sessionStorage.getItem('allRoles'));
            this.sharedDataService.showMenu = true;
            this.getCapabilities();
          }
        } else {
          sessionStorage.removeItem('user');
          this.hideUi = true;
          this.messageObject.type = 'error';
          this.messageObject.systemMessage = 'Not Authorized';
        }
      }, (error) => {
        sessionStorage.removeItem('user');
        this.messageObject.type = 'error';
        this.messageObject.systemMessage = 'Not Authorized';
        this.hideUi = true;
      });
    } else {
      if (sessionStorage.getItem('access-token')) {
        this.token = sessionStorage.getItem('access-token');
        this.sharedDataService.allRoles = JSON.parse(sessionStorage.getItem('allRoles'));
        this.sharedDataService.showMenu = true;
        this.getCapabilities();
      }
    }
  }
  getCapabilities = () => {
    this.apiservice.userCapabilities().subscribe(response => {
        let isUserNotAuthorized:boolean = false;
        if (response?.messages?.length>0){
            response.messages.forEach(e=> {
                if(e.systemMessage === 'Not Authorized'){
                    isUserNotAuthorized = true;
                    return;
                }
            })
        }
        if(isUserNotAuthorized){
            this.router.navigate(['/notAuthorized']);
        }else{
            const user = {
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                username: response.data.defaultRole.roleName,
                roleOid : response.data.defaultSecurityRoleOid
            };
            sessionStorage.setItem('user', JSON.stringify(user));
            this.userCapabilitiesResults = response.data;
            this.sharedDataService.capabilities = this.userCapabilitiesResults.defaultRole.screenCapabilities;
            this.sharedDataService.menuList = this.userCapabilitiesResults.defaultRole.menuList;
            this.sharedDataService.allRoles = this.userCapabilitiesResults.securityRoles;
            this.sharedDataService.featureCapabilities = this.userCapabilitiesResults.defaultRole.featureCapabilities;
            sessionStorage.setItem('allRoles', JSON.stringify(this.userCapabilitiesResults.securityRoles));
            sessionStorage.setItem('capabilities', JSON.stringify(this.userCapabilitiesResults.defaultRole.screenCapabilities));
        }

      // this.router.navigate([this.sharedDataService.initialRoute]);
    });
  }
  logOut = () => {
	
	this.reportService.getOktaEnabled().subscribe(resp => {
			if(resp!=undefined && resp!=null && resp.length!=0 && resp[0].value=="Y"){
				this.sharedDataService.showMenu = false;
			    sessionStorage.removeItem('access-token');
			    sessionStorage.removeItem('allRoles');
			    sessionStorage.removeItem('capabilities');
			    sessionStorage.removeItem('user');
			    sessionStorage.clear();
				localStorage.clear();  
				this.apiservice.authenticateLogout({}).subscribe(response => {
		        	//window.location.href=response.data;
		        	window.location.href = "/login"
	      			this.router.navigate(['/login']);
	      		});
			}else{
				this.apiservice.logout().subscribe(response => {
      
    			});
				this.logoutActivity();
			}
	});
    
  }

  logoutActivity(){
		this.sharedDataService.showMenu = false;
	    sessionStorage.removeItem('access-token');
	    sessionStorage.removeItem('allRoles');
	    sessionStorage.removeItem('user');  
	    sessionStorage.removeItem('capabilities');
		if (!environment.isUp) {
	      this.router.navigate(['dashboard/login']);
	    }  
	    if (environment.isUp) {
	      location.href = '/admin/logout.shtml';
	    }
	}
  getUserName = (): string => {
    let name = '';
    if (sessionStorage.getItem('user')) {
      const user: UserModel = <UserModel>JSON.parse(sessionStorage.getItem('user'));
      name = `${user.firstName} ${user.lastName} (${user.username})`;
    }
    return name;
  }
  updateOpen = (isOpen: boolean) => {
    this.isModalOpen = isOpen;
  }
  roleChange = () => {
    this.apiservice.roleSwitchCapabilities(this.role).subscribe(response => {
      this.sharedDataService.capabilities = response.screenCapabilities;
      this.sharedDataService.menuList = response.menuList;
      if (sessionStorage.getItem('user')) {
        const user: UserModel = <UserModel>JSON.parse(sessionStorage.getItem('user'));
        const obj: SecurityRolesVO = _.find(this.sharedDataService.allRoles, (o: SecurityRolesVO) => {
          return o.roleOid === parseInt(this.role, 10);
        });
        if (obj) {
          user.username = obj.roleName;
          user.roleOid = obj.roleOid;
          sessionStorage.setItem('user', JSON.stringify(user));
        }
      }
      //sessionStorage.setItem('capabilities', JSON.stringify(this.sharedDataService.capabilities));
      this.role = undefined;
      this.updateOpen(false);
      this.router.navigate(['login']);
    });
  }
  public onHomeRefresh = () => {

  }
  public get isLoggedIn(): boolean {
    return this.authUtil.isLoggedIn();
  }
  
  goToLoginPage(){
	this.sharedDataService.sessionExpired=false;
	this.router.navigate(['/login']);
}
}
