import { environment } from 'src/environments/environment';
export class Endpoints {
    public static regionTypeAheads = '/secure/jas/services/dismissalReviewQueue/getRegionCodes';
    public static getdaysofWeek = '/secure/jas/services/dayOfWeeks/getAll';
    public static updatemasterProfile = '/secure/jas/services/masterProfiles/updateProfile';
    public static savemasterProfile = '/secure/jas/services/masterProfiles/saveProfile';
    public static pointRangeBarService = '/secure/jas/services/monitor-attendance/pointRange';
    public static searchindividualHistory = '/secure/jas/services/individualHistory/searchHistory';
    public static searchIndividualHistoryByEmployeeId = '/secure/jas/services/individualHistory/searchHistoryByEmployeeId';
    public static getlayoffCodes = '/secure/jas/services/statusCodes/byType';
    public static getAlllayoffCodes = '/secure/jas/services/statusCodes/getAll';
    public static globalPropertiesSearch = '/secure/jas/services/applicationAttributes/getByNameAndType';
    public static getAllEmailTemplates = '/secure/jas/services/applicationAttributes/getByType';
    public static intiatePointGuage = '/secure/jas/services/monitor-attendance/initiatePointsGuage';
    public static getglobalProperties = '/secure/jas/services/applicationAttributes/getByType';
    public static attendanceMonitorPdf = '/secure/jas/services/pdf/attendanceMonitorPdf';
    public static searchchangeHistory = '/secure/jas/services/changeHistorySearch/search';
    public static editmasterProfile = '/secure/jas/services/masterProfiles/getProfile';
    public static serviceunitBar = '/secure/jas/services/monitor-attendance/serviceUnits';
    public static getAllLiteBoardTypes = '/secure/jas/services/boardType/getAllLiteBoardTypes';
    public static securityServiceUnitTypeAheads = '/secure/jas/services/employee/getAllDistinctServiceUnits';
    public static getLayoffReasonCodes = '/secure/jas/services/statusCodes/getAllReasonDetails'; 
    public static securityDistinctServiceUnitTypeAheads = '/secure/jas/services/employee/getAllDistinctServiceUnits';
    public static getFACTSReportStartDate = '/secure/jas/services/dismissalReviewQueue/getFACTSReportStartDate';
    public static dismissalreviewqueueSearch = '/secure/jas/services/dismissalReviewQueue/search';
    public static securityRegionTypeAheads = '/secure/jas/services/employee/getAllRegions';
    public static copymasterProfile = '/secure/jas/services/masterProfiles/copyProfile';
    public static getserviceCodes = '/secure/jas/services/dismissalReviewQueue/getServiceCodes';
    public static getCalenderView = '/secure/jas/services/individualHistory/calenderView';
    public static searchindividualSnapshot = '/secure/jas/services/individualSnapshot/searchSnapshotHistory';
    public static getregionorServiceUnit = '/secure/jas/services/regionOrServiceUnit/search';
    public static saveglobalProperties = '/secure/jas/services/applicationAttributes/save';
    public static securityUserEditPageLoadData = '/secure/jas/services/employee/getEmployee';
    public static deleteUserProfile = '/secure/jas/services/employee/deleteEmployee';
    public static raiseDispute = '/secure/jas/services/monitor-attendance/raiseDispute';
    public static saveUserProfile = '/secure/jas/services/employee/createEmployee';
    public static updateUserProfile = '/secure/jas/services/employee/updateEmployee';
    public static saveSecurityRole = '/secure/jas/services/security/createSecurityRole';
    public static updateSecurityRole = '/secure/jas/services/security/updateSecurityRole';
    public static editUserSecurity = '/secure/jas/services/employee/getEmployee';
    public static globalPropertiesSave = '/secure/jas/services/applicationAttributes/save';
    public static getglobalPropertiesEdit = '/secure/jas/services/applicationAttributes/getByOid';
    public static globalPropertiesUpdate = '/secure/jas/services/applicationAttributes/save';
    public static activeProfile = '/secure/jas/services/masterProfiles/hasActiveProfiles';
    public static userCapabilities = '/secure/jas/services/user/lookup';
    public static searchmasterProfile = '/secure/jas/services/masterProfiles/search';
    public static roleSwitchCapabilities = '/secure/jas/services/user/load-selected-role';
    public static authenticateUser = '/secure/jas/services/user/authenticate';
    public static userCopy = '/secure/jas/services/employee/copyEmployee';
    public static roleCopy = '/secure/jas/services/security/copySecurityRole';
    public static upAuthenticate =  '/secure/jas/services/authenticate';
    public static regionorServiceUnitPdf = '/secure/jas/services/pdf/regionOrServiceUnitPdf';
    public static districtSubDistrictSummaryPdf = '/secure/jas/services/pdf/districtSubDistrictSummaryPdf';
    public static individualSnapshotPdf = '/secure/jas/services/pdf/individualSnapshotPdf';
    public static masterProfilePdf = '/secure/jas/services/pdf/masterProfilePdf';
    public static boardTypeCodesSearch= '/secure/jas/services/boardType/getBoardTypeDetails';
    public static addBoardTypeCodes= '/secure/jas/services/boardType/createBoardType';
    public static updateBoardTypeCodes = '/secure/jas/services/boardType/updateBoardType';
    public static deleteBoardTypeCode= '/secure/jas/services/boardType/deleteBoardType';
    public static getAllBoardTypes = '/secure/jas/services/boardType/getAllBoardTypes';
    public static getAllAvailableBoardTypeCodess = '/secure/jas/services/boardType/getAllAvailableBoardTypeCodess';
    public static securityeditRole= '/secure/jas/services/security/getSecurityRole';
    public static boardTypeEditPageLoadData = '/secure/jas/services/boardType/getBoardTypeCodes';
    public static logout = '/secure/jas/services/logout';
    public static getAllJobsStatus =  '/secure/jas/services/jobControl/getAllJobsStatus';
    public static triggerScoreEventsJob =  '/secure/jas/services/triggerBatchJobs/scoreEventsJob';
    public static triggerScoreSummaryJob =  '/secure/jas/services/triggerBatchJobs/scoreSummaryJob';
    public static triggerDismissalReviewJob =  '/secure/jas/services/triggerBatchJobs/dismissalReviewJob';
    public static triggerDailyPointsJob =  '/secure/jas/services/triggerBatchJobs/dailyPointsJob';
    public static triggerMedgateCorrectionsJob =  '/secure/jas/services/triggerBatchJobs/medgateCorrectionsJob';
    public static triggerLayOffEventsJob =  '/secure/jas/services/triggerBatchJobs/layOffEventsJob';
    public static triggerCreditJob =  '/secure/jas/services/triggerBatchJobs/creditJob';
    public static triggerAlertMonitorJob =  '/secure/jas/services/triggerBatchJobs/camsAlertsMonitor';
    public static uploadSQLFile =  '/secure/jas/services/jobControl/uploadSQLFile';
    public static exceuteSQL =  '/secure/jas/services/jobControl/exceuteSQL';
    public static getMissedcallLayoffEvents =  '/secure/jas/services/missedcallReview/getLayoffEvents';
    public static validateMissedcallLayoff =  '/secure/jas/services/missedcallReview/validateLayoff';
    public static missedcallReviewPdfGeneration = '/secure/jas/services/pdf/missedcallReviewPdf';
    public static employeeMasterSearch = '/secure/jas/services/employeeMaster/search';   
    public static getEmployeeDetails = '/secure/jas/services/employeeMaster/getEmployeeDetailsByOid';    
    public static updateEmployeeMaster = '/secure/jas/services/employeeMaster/updateEmployee'; 
    public static updateEmployeeTEYFlag = '/secure/jas/services/employeeMaster/updateEmployeeTEYFlag';    
    public static getEmployeeHistory = '/secure/jas/services/employeeMaster/getEmployeeHistory'; 
    public static getemployeeGauge = '/secure/jas/services/monitor-attendance/historyEmployeeView';   
    public static securitySearchUsers ='/secure/jas/services/employee/getEmployees';
    public static roleSearch = '/secure/jas/services/security/search';
    public  static userDeleteUrl = '/secure/jas/services/employee/deleteEmployee';
    public static dismissalreviewqueueDelete = '/secure/jas/services/dismissalReviewQueue/delete';
    public static sendToAPDS = '/secure/jas/services/employeeDiscipline/sendToDisciplinarySystem';
    public static disputereviewqueueSearch = '/secure/jas/services/disputeData/searchDisputeData';
    public static disputequeueSearch = '/secure/jas/services/disputeData/searchDisputeData';
    public static medicalQueueSearch = '/secure/jas/services/medicalQueue/search';
    public static medicalQueueUpdateNotes = '/secure/jas/services/medicalQueue/updateNotes';
    public static employeeTypeAheads = '/secure/jas/services/employee/getEmployeeNameAndId';
    public static superVisorTypeAheads = '/secure/jas/services/regionOrServiceUnit/supervisorSearch';
    public static saveDispute = '/secure/jas/services/disputeData/updateDispute';
    public static serviceUnitSearch = '/secure/jas/services/regionOrServiceUnit/search';
    public static disputereviewqueuePdf = '/secure/jas/services/pdf/disputeReviewQueuePdf';
    public static serviceUnitsforRegions =  '/secure/jas/services/location/getServiceUnitsForRegions';
	public static authenticateOkta = '/secure/jas/services/okta/authenticate';
	public static authenticateOktaUser = '/secure/jas/services/okta/authenticate/userinfo';
	public static authenticateOktaLogout = '/secure/jas/services/okta/authenticate/logout';
    public static getCraftData = `/secure/jas/services/crafts/getCraftCodesByCraftType`;
    public static uploadEventLoad = `/secure/jas/services/eventload/upload`;
	public static getDisputeFiles = `/secure/jas/services/monitor-attendance/dispute/files/`;
	public static getDisputeFileDownload = `/secure/jas/services/monitor-attendance/dispute/download/`;
    public static saveLateToReportUrl = '/secure/jas/services/lateToReport/saveEvent';
    public static deleteEmployeeOrCraftProfile = '/secure/jas/services/employee/deleteEmployeeOrCraft';





}
