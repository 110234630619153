import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter,debounceTime } from 'rxjs/operators';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-trainmastercode',
  templateUrl: './trainmastercode.component.html',
  styleUrls: ['./trainmastercode.component.scss']
})
export class TrainmastercodeComponent implements OnInit {
  @Output() trainValueChange = new EventEmitter<any>();
  @Input() trainValue: string;
  trainItems: Observable<string[]>;
  train = new FormControl(["all"]);
  trainTemplate:any
  iconSearch = new FormControl();

  trainRange:any
  public selectTrain : string

  constructor(private trainservice: FiltersService) { }

  ngOnInit(): void {
    this.trainItems = this.iconSearch.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100),
    );

    this.getTrainData();
  }
  craftChange(event: any) {
    this.trainValue = this.train.value;
    this.emitValue(this.trainValue);
  }
  emitValue(value: any) {
    this.trainValueChange.emit(value);
  }
  trainData(event: CustomEvent){
    event.detail.formatInput(item => item.Name);
    this.selectTrain = event.detail.item.Name
  }


  getTrainData() {
    this.trainservice.getTrainData().subscribe((res: any) => {

    }, error => {

    })
  }
   changeValue(event: Event) {

  }

}
