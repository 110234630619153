import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DataAPIService } from 'src/app/common/data/dataAPI/data-api.service';
import {
  LayoffCodesModel,
  CheckboxValues,
  PickListModel,
  MessageObject,
  DaysofweekModel,
  PickListDaysModel,
  StartEndDateModel,
  MasterProfileModel,
  LayoffCodeSetupVO,
  LayoffCodesGroupVO,
  BoardTypeModel,
  PickListBoardTypesModel,
  DayofweekModel,
  SearchMasterProfileModel,
  StatusReasonCodesModel,
  QualifyingLayoffSetupModel,
  StepPoint,
  ApplicationAttributeVO, CraftGroupsModel, SpecialCondtionGroupVO
} from 'src/app/common/data/dataAPI/data-api.model';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as FileSaver from "file-saver";
import { ExporttoexcelService } from 'src/app/common/util/exportToExcel.service';
import { environment } from 'src/environments/environment';
import { AuthorizationUtilService } from 'src/app/common/util/authorization.util.service';
import { FeatureNames } from 'src/app/common/featureNames';
import { ScreenNames } from 'src/app/common/screenNames';
import {FiltersService} from "../../../../shared/filters/services/filters.service";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-create-attendance-profile',
  templateUrl: './create-attendance-profile.component.html',
  styleUrls: ['./create-attendance-profile.component.scss']
})
export class CreateAttendanceProfileComponent implements OnInit {

  public isModalOpen = false;
  public multiLine = true;
  public profileID = '';
  public description = '';
  public effectiveDate = '';
  public expirationDate = '';
  public comments = '';
  public warningStepPoints: number;
  public userNameState: any;
  public pointsreducedafterone: number;
  public emailstepone = '';
  public stepCheckbox = false;
  public dismissalStepPoints: number;
  public dismissalStepList: StepPoint[] = [];
  public stepNumber: number = 0;
  public pointsreducedaftertwo: number;
  public emailsteptwo = '';
  public ScreenNames = ScreenNames;
  public pointsstepreachedthree: number;
  public pointsreducedafterthree: number;
  public holidayselectedDate = '';
  public emailstepthree = '';
  public noOfDayswithoutincident: number;
  public pointsfordayswithoutincident: number;
  public pointsPerYearsOfService: number;
  public yearsOfService: number;
  public pointCheckbox = false;
  public qualifyCheckbox = false;
  public loading = false;
  public editorcopy = true;
  public featureNames = FeatureNames;
  public searchCriteria: SearchMasterProfileModel = new SearchMasterProfileModel();
  readonly creditSetupAvailableListLabel = "Disqualifying";
  readonly environment = environment;
  public dowConfig: DayofweekModel[] = [{
    day: 'Sunday',
    selected: false,
    startTime: null,
    endTime: null
  },
  {
    day: 'Monday',
    selected: false,
    startTime: null,
    endTime: null
  },
  {
    day: 'Tuesday',
    selected: false,
    startTime: null,
    endTime: null
  },
  {
    day: 'Wednesday',
    selected: false,
    startTime: null,
    endTime: null
  }
    ,
  {
    day: 'Thursday',
    selected: false,
    startTime: null,
    endTime: null
  }
    ,
  {
    day: 'Friday',
    selected: false,
    startTime: null,
    endTime: null
  },
  {
    day: 'Saturday',
    selected: false,
    startTime: null,
    endTime: null
  }
  ]

  public rolesPickListConfig = {
    id: 'statusCode',
    value: 'description',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'layoffCodes',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };
  public statusReasonCodePickListConfig = {
    id: 'statusCode',
    value: 'description',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'statusReasonCodes',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };

  public boardTypePickListConfig = {
    id: 'boardTypeOid',
    value: 'boardName',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'boardTypes',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };
  public rolesPickListConfigDays = {
    id: 'day',
    value: 'day',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'dow',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };
  public craftGroupPickListConfig = {
    id: 'type',
    value: 'type',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'craftGroups',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };



  // Available Lists
  public availableLayoffCodeSetup: LayoffCodesModel[][] = [];
  public availableLayoffCodeBoardTypeSetup: BoardTypeModel[][] = [];
  public availableDayOfWeekLayoffCodeSetup: LayoffCodesModel[][] = [];
  public availablePeakWeekLayoffCodeSetup: LayoffCodesModel[][] = [];
  public availableHolidayLayoffCodeSetup: LayoffCodesModel[][] = [];
  public availableQualifyingLayoffSetup: LayoffCodesModel[][] = [];
  public availablePointReductionlayoffCodeSetup: LayoffCodesModel[][] = [];
  public availableDaysofWeek: DaysofweekModel[][] = [];
  public availableSpecialConditionLayoffCodeSetup : LayoffCodesModel[][] = [];
  public availableSpecialConditionCraftGroup : CraftGroupsModel[][] = [];

  // Selected Lists
  public selectedLayoffCodeSetup: StatusReasonCodesModel[][] = [];
  public selectedLayoffCodeBoardTypeSetup: BoardTypeModel[][] = [];
  public selectedDayOfWeekLayoffCodeSetup: StatusReasonCodesModel[][] = [];
  public selectedPeakWeekLayoffCodeSetup: LayoffCodesModel[][] = [];
  public selectedHolidayLayoffCodeSetup: LayoffCodesModel[][] = [];
  public selectedQualifyingLayoffSetup: LayoffCodesModel[][] = [];
  public selectedPointReductionlayoffCodeSetup: LayoffCodesModel[][] = [];
  public selectedDaysofWeek: DaysofweekModel[][] = [];
  public selectedSpecialConditionLayoffCodeSetup : LayoffCodesModel[][] = [];
  public selectedSpecialConditionCraftGroup : CraftGroupsModel[][] = [];

  //Picklists
  public layoffCodeSetup: PickListModel[] = [];
  public layoffCodeBoardTypeSetup: PickListBoardTypesModel[] = [];
  public dayOfWeekLayoffCodeSetup: PickListModel[] = [];
  public peakWeekLayoffCodeSetup: PickListModel[] = [];
  public holidayLayoffCodeSetup: PickListModel[] = [];
  public specialConditionLayoffCodeSetup: PickListModel[] = [];

  public pointRedcutionLayoffCodeSetup: PickListModel[] = [];
  public qualifyingLayoffSetupModel: QualifyingLayoffSetupModel = new QualifyingLayoffSetupModel();
  public daysofWeeksetup: PickListDaysModel = new PickListDaysModel();

  public layoffCodes: LayoffCodesModel[] = [];
  public boardTypes: BoardTypeModel[] = [];
  public layoffCodesForSetup: LayoffCodesModel[] = [];
  public layoffCodesForDow: LayoffCodesModel[] = [];
  public layoffCodesForPeakWeek: LayoffCodesModel[] = [];
  public layoffCodesForHoliday: LayoffCodesModel[] = [];


  public statusReasonCodes: LayoffCodesModel[] = [];
  public craftGroups: CraftGroupsModel[] = [];
  public statusReasonCodesForSetup: LayoffCodesModel[] = [];
  public statusReasonCodesForDow: LayoffCodesModel[] = [];
  public statusReasonCodesForPeakWeek: LayoffCodesModel[] = [];
  public statusReasonCodesForHoliday: LayoffCodesModel[] = [];
  public stepProgressionActions: ApplicationAttributeVO[] = []
  public specialConditionActions: ApplicationAttributeVO[] = []
  public craftGroupsForSpecialConditions: CraftGroupsModel[] = []


  public pointReductionLayoffCodes: LayoffCodesModel[] = [];
  public daysofWeeks: DaysofweekModel[] = [];
  private checkboxValues = new CheckboxValues();

  public selectedList = [];
  public fromDate = '';
  public toDate = '';
  public editMode = false;
  public copyMode = false;
  public selectedWeeks: StartEndDateModel[] = [];
  public selectedDays: any[] = [];
  public selectedDay = '';
  public editResults: MasterProfileModel = new MasterProfileModel();
  public messageObject = new MessageObject();
  public selectedSpecialCondActions = new FormControl();
  public loadingMessage = '';
  panelStatus = [true, false, false, false, false, false,false];
  propagation = true;

  constructor(private apiservice: DataAPIService,private filtersService: FiltersService, public authUtil: AuthorizationUtilService, private activatedRoute: ActivatedRoute, private router: Router, public exportToExcel: ExporttoexcelService) {
    // this.getStatusReasonCodes();
    this.getCraftGroups();
    this.getlayoffCodes();
    this.getdaysofWeek();
    this.getBoardTypes();
    this.getStepProgActionTemplates("Step Progression Action");
    this.getSpecialCondActionTemplates("Special Condition Action");
    this.defaultStep();
  }

  ngOnInit() {
    this.authUtil.isFeatureAuthorized(this.featureNames.attendanceProfileBoardType);
    this.activatedRoute.params.subscribe(params => {
      if (params.oid && params.action) {
        if (params.action === 'edit') {
          this.editMode = true;
          this.loadingMessage = 'Loading'
          this.loading = true;
          this.editorcopy = false;
          this.messageObject = new MessageObject();
          this.apiservice.getMasterProfile(params.oid).subscribe(response => {
            response.layoffCodeSetupVOs.forEach(element => {
              element.layoffCodesGroupVO.statusCodes.forEach(statusElement => {
                statusElement.description = statusElement.description + " (" + statusElement.statusCode + ")"
              })
            })
            response.qualifyingLayoffSetupVO.layoffCodesGroupVOs.forEach(element => {
              element.statusCodes.forEach(statusElement => {
                statusElement.description = statusElement.description + " (" + statusElement.statusCode + ")"
              })
            })
            this.editResults = response;
            this.getlayoffCodes();
            setTimeout(() => {
              this.loading = false;
            }, 2000);
           


          });
        }
        else if (params.action === 'copy') {
          this.copyMode = true;
          this.editorcopy = false;
          this.messageObject = new MessageObject();
          this.loading = true;
          this.apiservice.copymasterProfile(params.oid).subscribe(response => {
            this.editResults = response;
            this.loading = false;
            this.getlayoffCodes();
          });
        }
      }
      else {
        this.apiservice.searchmasterProfile(this.searchCriteria).subscribe(response => {
          let searchResults = response.data;
          var openEndedProfile = searchResults.find(profile => profile.expiryDate == '' || profile.expiryDate == undefined);
          if (openEndedProfile != null) {
            this.messageObject.type = 'warning';
            this.messageObject.systemMessage = 'Previous profile expiry date will be set based on new profile effective date';
          }

        })
      }
    });


  }

  private initiatePicklists = () => {
    this.availableLayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
    this.selectedLayoffCodeSetup[0] = [];
    let layoffpickListConfig = _.cloneDeep(this.rolesPickListConfig);
    layoffpickListConfig.name = 'initialLayoff';
    this.layoffCodeSetup.push(<PickListModel>{
      statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
    });

    this.availableDayOfWeekLayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
    this.selectedDayOfWeekLayoffCodeSetup[0] = [];
    let pickListConfig = _.cloneDeep(this.rolesPickListConfig);
    pickListConfig.name = 'dowLayoff';
    this.dayOfWeekLayoffCodeSetup.push(<PickListModel>{
      statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
    });

    this.availablePeakWeekLayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
    this.selectedPeakWeekLayoffCodeSetup[0] = [];
    let peakweekListConfig = _.cloneDeep(this.rolesPickListConfig);
    peakweekListConfig.name = 'peakweekLayoff';
    this.peakWeekLayoffCodeSetup.push(<PickListModel>{
      statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
    });

    this.availableHolidayLayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
    this.selectedHolidayLayoffCodeSetup[0] = [];
    let holidayListConfig = _.cloneDeep(this.rolesPickListConfig);
    holidayListConfig.name = 'holidayListLayoff';
    this.holidayLayoffCodeSetup.push(<PickListModel>{
      statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
    });

    this.availablePointReductionlayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
    this.selectedPointReductionlayoffCodeSetup[0] = [];
    let pointReductionListConfig = _.cloneDeep(this.rolesPickListConfig);
    pointReductionListConfig.name = 'pntRdctnListLayoff';
    this.pointRedcutionLayoffCodeSetup.push(<PickListModel>{
      pickListConfig: pointReductionListConfig, availableList: this.availablePointReductionlayoffCodeSetup[0], selectedList: this.selectedPointReductionlayoffCodeSetup[0],
    });

    this.availableQualifyingLayoffSetup[0] = _.cloneDeep(this.layoffCodes);
    this.selectedQualifyingLayoffSetup[0] = [];
    let qualifyingLayoffListConfig = _.cloneDeep(this.rolesPickListConfig);
    qualifyingLayoffListConfig.name = 'qualifyingListLayoff';
    this.qualifyingLayoffSetupModel.qualifyingLayoffSetup.push(<PickListModel>{
      statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
    });
    this.availableSpecialConditionLayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
    this.selectedSpecialConditionLayoffCodeSetup[0] = [];
    this.availableSpecialConditionCraftGroup[0] = _.cloneDeep(this.craftGroups);
    this.selectedSpecialConditionLayoffCodeSetup[0] = [];
    layoffpickListConfig.name = 'specialCond';
    this.specialConditionLayoffCodeSetup.push(<PickListModel>{
      statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes),
      selectedStatusReasonCodesList: [],craftGroupPickListConfig: _.cloneDeep(this.craftGroupPickListConfig),availableCraftGroupsList : _.cloneDeep(this.craftGroups),selectedCraftGroupsList: [],
    });
  }

  public onOpenChange(index: number, value: boolean) {
    this.panelStatus[index] = value;
  }

  public onSelectChange(event: CustomEvent) {
    const { detail } = event;
    const message = !this.propagation
      ? `Could not move from index ${detail.closeIndex} to ${detail.openIndex
      }`
      : `Moving from index ${detail.closeIndex} to ${detail.openIndex}`;
    !this.propagation && event.stopPropagation();
  }

  private initiateBoardTypePicklists = () => {
    this.availableLayoffCodeBoardTypeSetup[0] = _.cloneDeep(this.boardTypes);
    this.selectedLayoffCodeBoardTypeSetup[0] = [];
    let layoffBoardTypepickListConfig = _.cloneDeep(this.boardTypePickListConfig);
    layoffBoardTypepickListConfig.name = 'initialLayoff';
    this.layoffCodeBoardTypeSetup.push(<PickListBoardTypesModel>{ pickListConfig: layoffBoardTypepickListConfig, availableList: this.availableLayoffCodeBoardTypeSetup[0], selectedList: this.selectedLayoffCodeBoardTypeSetup[0] });


  }
  private initiatePointReductionPicklists = () => {
    // this.availableList = _.cloneDeep(this.pointReductionLayoffCodes);
    // this.selectedList = [];
    // this.availablePntRedBoardsList = _.cloneDeep(this.boardTypes);
    // this.selectedPntRedBoardsList = [];
    // this.availablePointReductionlayoffCodeSetup[0] = _.cloneDeep(this.pointReductionLayoffCodes);
    // this.selectedPointReductionlayoffCodeSetup[0] = [];
    // this.pointRedcutionLayoffCodeSetup.push(<PicklListModel>{ pickListConfig: this.rolesPickListConfig, availableList: this.availablePointReductionlayoffCodeSetup[0], selectedList: this.selectedPointReductionlayoffCodeSetup[0] });
  }
  private initiatedaysofWeekPicklists = () => {
    this.availableDaysofWeek[0] = this.daysofWeeks;
    this.selectedDaysofWeek[0] = [];
    this.daysofWeeksetup = <PickListDaysModel>{ pickListConfig: this.rolesPickListConfigDays, availableList: this.availableDaysofWeek[0], selectedList: this.selectedDaysofWeek[0] };
  }


  updateOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }
  public onAdd = (index, arraytoAdd, available, selected, availableArray) => {
    console.log('sldjsldjskdsk', index, arraytoAdd, available, selected, availableArray)
    available[index + 1] = availableArray;
    selected[index + 1] = [];
    const layoffCode = {
      statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
    };
    layoffCode.statusReasonCodePickListConfig.name = layoffCode.statusReasonCodePickListConfig.name + (arraytoAdd.length + 1);
    arraytoAdd.splice(index + 1, 0, layoffCode);
  }
  public onRemove = (index, arraytoAdd, available, selected) => {
    arraytoAdd.splice(index, 1);
    available.splice(index, 1);
    selected.splice(index, 1);
  }
  public changeDate = (date, datevalue) => {
    this[datevalue] = date;
  }
  public changetoDate = (date) => {
    this.fromDate = date;
    this.toDate = moment(date).add('days', 6).format("YYYY-MM-DD");
  }
  checkboxChange = (param) => {
    this[param] = !this[param];
  }
  public changetoDay = (date) => {
    this.selectedDay = date;
  }
  public onweekAdd = () => {
    const dateObj: StartEndDateModel = {
      startDate: this.fromDate,
      endDate: this.toDate
    }
    this.selectedWeeks.push(dateObj);
  }
  public onselectedweekDelete = (index) => {
    this.selectedWeeks.splice(index, 1);
  }
  public ondayAdd = () => {
    const day = moment(this.selectedDay).format("YYYY-MM-DD");
    let dayfound = _.find(this.selectedDays, (o) => {
      return o.date === day;
    });
    if (!dayfound) {
      this.selectedDays.push({ 'date': day });
    }
    else {
      this.messageObject.type = 'error';
      this.messageObject.systemMessage = "Please add a new day";
      window.scrollTo(0, 0);
    }
  }
  public onselecteddayDelete = (index) => {
    this.selectedDays.splice(index, 1);
  }
  private getStepProgActionTemplates = (type : string) =>{
    this.apiservice.getActionTemplates(type).subscribe(response => {
      this.stepProgressionActions =  response;
    });
  }
  private getSpecialCondActionTemplates = (type : string) =>{
    this.apiservice.getActionTemplates(type).subscribe(response => {
      this.specialConditionActions =  response;
      this.specialConditionActions.forEach(e => {
        e.description = e.name+' '+e.value+' events';
      })
    });
  }
  private getCraftGroups = () =>{
    this.messageObject = new MessageObject();
    this.filtersService.getEmployeeData().subscribe(response => {
      this.craftGroupsForSpecialConditions = response;
      this.craftGroups = response;
    })
  }
  private getlayoffCodes = () => {
    this.messageObject = new MessageObject();
    this.apiservice.getAlllayoffCodes().subscribe(response => {
      response.forEach(element => {
        delete element['activeFlag'];
        delete element['disqualifyFlag'];        
        element['description'] = element.description + " (" + element.statusCode + ")"
      });
      this.statusReasonCodes = response;
      this.statusReasonCodesForSetup = response;
      this.statusReasonCodesForDow = response;
      this.statusReasonCodesForHoliday = response;
      this.statusReasonCodesForPeakWeek = response;
      if (!this.copyMode && !this.editMode) {
        this.initiatePicklists();
      } else {
        this.convertToViewModel();
      }
      this.initiatePointReductionPicklists();
    });
  }
  private getStatusReasonCodes = () => {
    this.messageObject = new MessageObject();
    this.apiservice.getStatusReasonCodes().subscribe(response => {
      // this.statusReasonCodes = response;
      // this.statusReasonCodesForSetup = response;
      // this.statusReasonCodesForDow = response;
      // this.statusReasonCodesForHoliday = response;
      // this.statusReasonCodesForPeakWeek = response;
      this.initiatePicklists();
      this.initiatePointReductionPicklists();
    });
  }
  private getBoardTypes = () => {
    this.messageObject = new MessageObject();
    this.apiservice.getBoardTypes().subscribe(response => {
      this.boardTypes = response;
      this.initiateBoardTypePicklists();
    })
    this.initiateBoardTypePicklists();
  }

  private getdaysofWeek = () => {
    this.messageObject = new MessageObject();
    this.apiservice.getdaysofWeek().subscribe(response => {
      this.daysofWeeks = response;
      if (!this.editMode && !this.copyMode) {
        this.initiatedaysofWeekPicklists();
      }
    })
  }
  private constructSavePayload = () => {
    this.loading = true;
    const payload = {
      createdUser: null,
      createdTimeStamp: null,
      lastUpdatedUser: null,
      lastUpdatedTimeStamp: null,
      profileId: this.profileID,
      description: this.description,
      effectiveDate: moment(this.effectiveDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      expiryDate: (this.expirationDate) ? (moment(this.expirationDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")) : null,
      comments: this.comments,
      layoffCodeSetupVOs: [],

      dayofWeekSetupVO: {
        dayOfweekVOs: [],
        layoffCodesGroupVOs: []
      },
      peakWeekSetupVO: {
        peakWeekVOs: this.selectedWeeks,
        layoffCodesGroupVOs: []
      },
      holidaySetupVO: {
        holidayVOs: this.selectedDays,
        layoffCodesGroupVOs: []
      },
      qualifyingLayoffSetupVO: {
        layoffCodesGroupVOs: [],
        action: this.checkboxValues[this.qualifyCheckbox.toString()],
        periodWithoutIncident: this.qualifyingLayoffSetupModel.periodWithoutIncident,
        pointsPerPeriodWithoutIncident: this.qualifyingLayoffSetupModel.pointsPerPeriodWithoutIncident,
        pointsPerYearWithoutIncident: this.qualifyingLayoffSetupModel.pointsPerYearWithoutIncident
      },
      pointReductionSetupVO: {
        layoffCodesGroupVOs: [],
        action: this.checkboxValues[this.pointCheckbox.toString()],
        noOfDaysWithoutIncident: this.noOfDayswithoutincident,
        pointsForDaysWithoutIncident: this.pointsfordayswithoutincident
      },

      stepProgressionSetupVO: {
        action: this.checkboxValues[this.stepCheckbox.toString()],
        pointsPerYearsOfService: this.pointsPerYearsOfService,
        yearsOfService: this.yearsOfService,
        dismissalStepsList: this.dismissalStepList,
      },
      specialConditionCodeSetupVOS: {}
    }
    payload.peakWeekSetupVO.peakWeekVOs.forEach(element => {
      element.startDate = moment(element.startDate).format("YYYY-MM-DD");
      element.endDate = moment(element.endDate).format("YYYY-MM-DD"), 0;
    });
    payload.dayofWeekSetupVO.dayOfweekVOs = this.dowConfig.filter(e => e.selected);
    payload.dayofWeekSetupVO.layoffCodesGroupVOs = this.getLayoffCodesGroupVo(this.dayOfWeekLayoffCodeSetup);
    payload.peakWeekSetupVO.layoffCodesGroupVOs = this.getLayoffCodesGroupVo(this.peakWeekLayoffCodeSetup);
    payload.holidaySetupVO.layoffCodesGroupVOs = this.getLayoffCodesGroupVo(this.holidayLayoffCodeSetup);
    payload.qualifyingLayoffSetupVO.layoffCodesGroupVOs = this.getLayoffCodesGroupVo(this.qualifyingLayoffSetupModel.qualifyingLayoffSetup);
    payload.pointReductionSetupVO.layoffCodesGroupVOs = this.getLayoffCodesGroupVo(this.pointRedcutionLayoffCodeSetup);
    payload.layoffCodeSetupVOs = this.getLayoffCodesSetupGroupVo(this.layoffCodeSetup);
    payload.specialConditionCodeSetupVOS = this.getSpecialConditionsSetupGroupVo(this.specialConditionLayoffCodeSetup);
    if (payload.layoffCodeSetupVOs.length === 1) {
      if (payload.layoffCodeSetupVOs[0].layoffCodesGroupVO.statusCodes.length === 0) {
        payload.layoffCodeSetupVOs = null;
      }
    }

    if (this.editMode) {
      payload['oid'] = this.editResults.oid;
    }
    return payload;
  }

  availableChange = (array, setupArray, availableArray) => {
    this[availableArray] = array;
    this[setupArray].forEach(code => {
      setTimeout(() => {
        code.availableList = _.differenceWith(array, code.selectedList, _.isEqual);
      }, 0);
    });
  }
  availableBoardTypeChange = (setup) => {
    setup.availableBoardsList = _.differenceWith(this.boardTypes, setup.selectedBoardsList, _.isEqual);
  }
  private getLayoffCodesGroupVo = (layoffCodes: PickListModel[]) => {
    let defaultBoard: BoardTypeModel = {
      boardTypeOid: 1,
      boardName: "All"
    }
    let defaultBoardList: BoardTypeModel[] = [];
    defaultBoardList.push(defaultBoard);
    let codesGroup = [];
    layoffCodes.forEach((element: PickListModel, index: number) => {
      const obj = {
        // statusCodes: element.selectedList,
        statusCodes: element.selectedStatusReasonCodesList,
        boardTypes: element.selectedBoardsList,
        points: element.points,
        pointsAfter24Hours: element.pointsAfter24Hours,
        groupId: index + 1
      }
      if (!this.authUtil.isFeatureAuthorized(this.featureNames.attendanceProfileBoardType)) {
        obj.boardTypes = defaultBoardList;
      }
      codesGroup.push(obj);
    });
    return codesGroup;
  }

  private getLayoffCodesSetupGroupVo = (layoffCodes: PickListModel[]) => {
    let defaultBoard: BoardTypeModel = {
      boardTypeOid: 1,
      boardName: "All"
    }
    let defaultBoardlList: BoardTypeModel[] = [];
    defaultBoardlList.push(defaultBoard);
    let codesGroup = [];
    layoffCodes.forEach((element: PickListModel, index: number) => {
      const obj = {
        beforeAfterRestDayPoints: element.beforeOrAfterPoint,
        layoffCodesGroupVO: {
          // statusCodes: element.selectedList,
          statusCodes: element.selectedStatusReasonCodesList,
          boardTypes: element.selectedBoardsList,
          points: element.points,
          pointsAfter24Hours: element.pointsAfter24Hours,
          groupId: index + 1
        }
      }
      if (!this.authUtil.isFeatureAuthorized(this.featureNames.attendanceProfileBoardType)) {
        obj.layoffCodesGroupVO.boardTypes = defaultBoardlList;
      }
      codesGroup.push(obj);
    });
    return codesGroup;
  }
  private getSpecialConditionsSetupGroupVo = (specialCondtionSetupVos: PickListModel[]) => {
    let codesGroup:SpecialCondtionGroupVO[] = [];
    specialCondtionSetupVos.forEach((element: PickListModel, index: number) => {
      const obj : SpecialCondtionGroupVO = {
          // statusCodes: element.selectedList,
          statusCodes: element.selectedStatusReasonCodesList,
          craftGroups : element.selectedCraftGroupsList,
          groupId: index + 1,
          actionOid: element.actionOid
      }
      codesGroup.push(obj);
    });
    return codesGroup;
  }
  public onMasterProfileSave = () => {
    const payload = this.constructSavePayload();
    if (this.editMode) {
      this.messageObject = new MessageObject();
      this.apiservice.updatemasterProfile(payload).subscribe(response => {
        this.messageObject.type = response.result.toLowerCase();
        response.messages.forEach(element => {
          this.messageObject.systemMessages.push(element.systemMessage);
        });
        //this.messageObject.systemMessage = response.messages[0].systemMessage;
        window.scrollTo(0, 0);
        setTimeout(() => {
          if (response.result == 'SUCCESS') {
            this.router.navigate(['attendanceProfiles']);
          }
        }, 1000);
        this.loading = false;
      },
        error => {
          this.messageObject.type = 'error';
          this.loading = false;
          this.messageObject.systemMessage = error.systemMessage;
        })
    }
    else {
      this.messageObject = new MessageObject();
      this.apiservice.savemasterProfile(payload).subscribe(response => {
        this.messageObject.type = response.result.toLowerCase();
        response.messages.forEach(element => {
          this.messageObject.systemMessages.push(element.systemMessage);
        });
        //this.messageObject.systemMessage = response.messages[0].systemMessage;
        window.scrollTo(0, 0);
        setTimeout(() => {
          if (response.result == 'SUCCESS') {
            this.router.navigate(['attendanceProfiles']);

          }
        }, 3000);
        this.loading = false;
        // if (response.result == 'SUCCESS') {
        //   this.router.navigate(['attendanceProfiles']);

        // }
      }, error => {
        this.messageObject.type = 'error';
        this.loading = false;
        this.messageObject.systemMessage = error.systemMessage;
      });
    }

  }
  private convertToViewModel = () => {
    if (!this.copyMode) {
      this.profileID = this.editResults.profileId;
      if (this.editResults.effectiveDate) {
        this.effectiveDate = moment(this.editResults.effectiveDate).format("YYYY-MM-DD");
      }
      if (this.editResults.expiryDate) {
        this.expirationDate = moment(this.editResults.expiryDate).format("YYYY-MM-DD");

      }
    }
    this.description = this.editResults.description
    this.comments = this.editResults.comments;
    this.selectedWeeks = [];
    if(this.editResults.peakWeekSetupVO && this.editResults.peakWeekSetupVO.peakWeekVOs){
      this.selectedWeeks = this.editResults.peakWeekSetupVO.peakWeekVOs;
    }
    this.selectedWeeks.forEach(element => {
      element.endDate = moment(element.endDate).format("YYYY-MM-DD");
      element.startDate = moment(element.startDate).format("YYYY-MM-DD");
    });
    if(this.editResults.holidaySetupVO && this.editResults.holidaySetupVO.holidayVOs){
      this.selectedDays = this.editResults.holidaySetupVO.holidayVOs
    }else{
      this.selectedDays = [];
    }
    this.pointsPerYearsOfService = this.editResults.stepProgressionSetupVO ? this.editResults.stepProgressionSetupVO.pointsPerYearsOfService : null;
    this.yearsOfService = this.editResults.stepProgressionSetupVO ? this.editResults.stepProgressionSetupVO.yearsOfService : null;
    this.dismissalStepList = this.editResults.stepProgressionSetupVO ? this.editResults.stepProgressionSetupVO.dismissalStepsList : [];
    if(this.dismissalStepList && this.dismissalStepList.length == 0){
      this.stepNumber = 0;
      this.defaultStep();
    }
    this.convertLayoffSetupCodes();
    this.convertToDaysofWeek();
    this.peakWeekLayoffCodeSetup = this.convertLayoffCodestoViewModel(this.editResults.peakWeekSetupVO.layoffCodesGroupVOs, 'peakweekLayoff', 'layoffCodesForPeakWeek');
    this.holidayLayoffCodeSetup = this.convertLayoffCodestoViewModel(this.editResults.holidaySetupVO.layoffCodesGroupVOs, 'holidayListLayoff', 'layoffCodesForHoliday');
    this.qualifyingLayoffSetupModel.qualifyingLayoffSetup = this.convertLayoffCodestoViewModel(this.editResults.qualifyingLayoffSetupVO.layoffCodesGroupVOs, 'qualifyingListLayoff', 'layoffForQualifying');
    this.qualifyingLayoffSetupModel.periodWithoutIncident = this.editResults.qualifyingLayoffSetupVO.periodWithoutIncident;
    this.qualifyingLayoffSetupModel.pointsPerPeriodWithoutIncident = this.editResults.qualifyingLayoffSetupVO.pointsPerPeriodWithoutIncident;
    this.qualifyingLayoffSetupModel.pointsPerYearWithoutIncident = this.editResults.qualifyingLayoffSetupVO.pointsPerYearWithoutIncident;
    this.dayOfWeekLayoffCodeSetup = this.convertLayoffCodestoViewModel(this.editResults.dayofWeekSetupVO.layoffCodesGroupVOs, 'dowLayoff', 'layoffCodesForDow');
    this.pointRedcutionLayoffCodeSetup = this.convertLayoffCodestoViewModel(this.editResults.pointReductionSetupVO.layoffCodesGroupVOs, 'pntRdctnListLayoff', 'pointReductionLayoffCodes');
    this.specialConditionLayoffCodeSetup = this.convertSpecialConditionToViewModel(this.editResults.specialConditionCodeSetupVOS,'specialCond','layoffCodesForSpecial')
    this.noOfDayswithoutincident = this.editResults.pointReductionSetupVO ? this.editResults.pointReductionSetupVO.noOfDaysWithoutIncident : null;
    this.pointsfordayswithoutincident = this.editResults.pointReductionSetupVO ? this.editResults.pointReductionSetupVO.pointsForDaysWithoutIncident : null;
    this.stepCheckbox = (this.editResults.stepProgressionSetupVO && this.editResults.stepProgressionSetupVO.action === 'Y');
    this.pointCheckbox = (this.editResults.pointReductionSetupVO && this.editResults.pointReductionSetupVO.action === 'Y');
    this.qualifyCheckbox = (this.editResults.qualifyingLayoffSetupVO && this.editResults.qualifyingLayoffSetupVO.action === 'Y');

    this.noOfDayswithoutincident = this.editResults.pointReductionSetupVO ? this.editResults.pointReductionSetupVO.noOfDaysWithoutIncident : null;
    this.pointsfordayswithoutincident = this.editResults.pointReductionSetupVO ? this.editResults.pointReductionSetupVO.pointsForDaysWithoutIncident : null;
  }

  private convertLayoffSetupCodes = () => {
    this.layoffCodeSetup = [];
    this.editResults.layoffCodeSetupVOs.forEach((element: LayoffCodeSetupVO, index) => {
      const obj = new PickListModel();
      obj.statusReasonCodePickListConfig = _.cloneDeep(this.statusReasonCodePickListConfig);
      obj.statusReasonCodePickListConfig.name = 'statusReasonCodePickListConfig' + index;
      obj.beforeOrAfterPoint = element.beforeAfterRestDayPoints;
      obj.pointsAfter24Hours = element.layoffCodesGroupVO.pointsAfter24Hours;
      obj.points = element.layoffCodesGroupVO.points;
      obj.selectedStatusReasonCodesList = element.layoffCodesGroupVO.statusCodes;
      let data = [];
      for(let i = 0; i< this.statusReasonCodes.length; i++) {
        const index = obj.selectedStatusReasonCodesList.findIndex((x: any) => x.statusCodeOid == this.statusReasonCodes[i].statusCodeOid);
        if(index == -1) {
          data.push(this.statusReasonCodes[i])
        }
      }
      obj.availableStatusReasonCodesList = data;
      // obj.availableStatusReasonCodesList = _.differenceWith(_.cloneDeep(this.statusReasonCodes), obj.selectedStatusReasonCodesList, _.isEqual);
      this.layoffCodesForSetup = obj.availableList;
      this.layoffCodeSetup.push(obj);
    });
    if (this.layoffCodeSetup.length == 0) {
      this.availableLayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
      this.selectedLayoffCodeSetup[0] = [];
      this.availableLayoffCodeBoardTypeSetup[0] = _.cloneDeep(this.boardTypes);
      this.selectedLayoffCodeBoardTypeSetup[0] = [];
      this.layoffCodeSetup.push(<PickListModel>{
        statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig),
        availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
      });
    }
    if (this.peakWeekLayoffCodeSetup.length == 0) {
      this.availablePeakWeekLayoffCodeSetup[0] = _.cloneDeep(this.layoffCodes);
      this.selectedPeakWeekLayoffCodeSetup[0] = [];
      this.peakWeekLayoffCodeSetup.push(<PickListModel>{
        statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: []
      });
    }
  }
  private convertLayoffCodestoViewModel = (layoffCodesGroupVO: any[], configName: string, layoffCodes: string) => {
    let codes: PickListModel[] = [];
    layoffCodesGroupVO.forEach((element: LayoffCodesGroupVO, index) => {
      const obj = new PickListModel();
      obj.statusReasonCodePickListConfig = _.cloneDeep(this.statusReasonCodePickListConfig);
      obj.statusReasonCodePickListConfig.name = configName + index;
      obj.points = element.points;
      obj.pointsAfter24Hours = element.pointsAfter24Hours;
      obj.selectedStatusReasonCodesList = element.statusCodes;
      let data = [];
      for(let i = 0; i< this.statusReasonCodes.length; i++) {
        const index = obj.selectedStatusReasonCodesList.findIndex((x: any) => x.statusCodeOid == this.statusReasonCodes[i].statusCodeOid);
        if(index == -1) {
          data.push(this.statusReasonCodes[i])
        }
      }
      obj.availableStatusReasonCodesList = data;
      // obj.availableStatusReasonCodesList = _.differenceWith(_.cloneDeep(this.statusReasonCodes), obj.selectedStatusReasonCodesList, _.isEqual);
      codes.push(obj);
    });
    if (codes.length == 0) {
      codes.push(<PickListModel>{
        statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: []
      });
    }
    return codes;
  }
  private convertSpecialConditionToViewModel = (specialConditionsGroupVO: any[], configName: string, layoffCodes: string) => {
    let codes: PickListModel[] = [];
    specialConditionsGroupVO.forEach((element: SpecialCondtionGroupVO, index) => {
      const obj = new PickListModel();
      obj.statusReasonCodePickListConfig = _.cloneDeep(this.statusReasonCodePickListConfig);
      obj.statusReasonCodePickListConfig.name = configName + index;
      obj.selectedStatusReasonCodesList = element.statusCodes;
      obj.craftGroupPickListConfig = _.cloneDeep(this.craftGroupPickListConfig);
      obj.craftGroupPickListConfig.name = configName + 'CG'+index;
      obj.selectedCraftGroupsList = element.craftGroups;
      let data = [];
      for(let i = 0; i< this.statusReasonCodes.length; i++) {
        const index = obj.selectedStatusReasonCodesList.findIndex((x: any) => x.statusCodeOid == this.statusReasonCodes[i].statusCodeOid);
        if(index == -1) {
          data.push(this.statusReasonCodes[i])
        }
      }
      obj.availableStatusReasonCodesList = data;

      data = [];
      for(let i = 0; i< this.craftGroups.length; i++) {
        const index = obj.selectedCraftGroupsList.findIndex((x: any) => x.type == this.craftGroups[i].type);
        if(index == -1) {
          data.push(this.craftGroups[i])
        }
      }
      obj.availableCraftGroupsList = data;
      obj.actionOid = element.actionOid;
      // obj.availableStatusReasonCodesList = _.differenceWith(_.cloneDeep(this.statusReasonCodes), obj.selectedStatusReasonCodesList, _.isEqual);
      codes.push(obj);
    });
    if (codes.length == 0) {
      codes.push(<PickListModel>{
        statusReasonCodePickListConfig: _.cloneDeep(this.statusReasonCodePickListConfig), availableStatusReasonCodesList: _.cloneDeep(this.statusReasonCodes), selectedStatusReasonCodesList: [],
        craftGroupPickListConfig: _.cloneDeep(this.craftGroupPickListConfig), availableCraftGroupsList: _.cloneDeep(this.craftGroups), selectedCraftGroupsList: []
      });
    }
    return codes;
  }
  private defaultStep = () => {
    let newStep: StepPoint = new StepPoint();
    this.stepNumber = this.stepNumber + 1;
    newStep.stepNum = this.stepNumber.toString(),
      newStep.stepPoints = "",
      newStep.actionTemplateOid = null
      this.dismissalStepList.push(newStep);
  }

  private addNewStep = (index) => {
    let newStep: StepPoint = new StepPoint();
    newStep.stepPoints = "";
    newStep.actionTemplateOid = null;
    if (index > 0 && index < this.dismissalStepList.length - 1) {
      let stepNumber = parseInt(this.dismissalStepList[index].stepNum) + 1;
      newStep.stepNum = stepNumber.toString();
      for (let i = index + 1; i < this.dismissalStepList.length; i++) {
        this.dismissalStepList[i].stepNum = (parseInt(this.dismissalStepList[i].stepNum) + 1).toString();
      }
      this.dismissalStepList.splice(index + 1, 0, newStep);
    } else {
      this.stepNumber = this.dismissalStepList.length + 1;
      newStep.stepNum = this.stepNumber.toString();
      this.dismissalStepList.push(newStep);
    }

  }
  onRemoveStep = (index) => {
    for (let i = index + 1; i < this.dismissalStepList.length; i++) {
      this.dismissalStepList[i].stepNum = (parseInt(this.dismissalStepList[i].stepNum) - 1).toString();
    }
    this.dismissalStepList.splice(index, 1)
    this.stepNumber = this.stepNumber - 1;
  }
  private convertToDaysofWeek = () => {
    if(this.editResults.dayofWeekSetupVO && this.editResults.dayofWeekSetupVO.dayOfweekVOs){
      this.editResults.dayofWeekSetupVO.dayOfweekVOs.forEach(ele => {
        this.dowConfig.forEach(config => {
          if (config.day == ele.day) {
            config.startTime = ele.startTime;
            config.endTime = ele.endTime;
            config.selected = true;
          }
        })
      })
    }
  };

  private onDayofWeekSelect(item: DayofweekModel, i: number) {
    if (!item.selected) {
      this.dowConfig[i].startTime = undefined;
      this.dowConfig[i].endTime = undefined;
    }
  }

  public onReset = () => {
    this.profileID = "";
    this.effectiveDate = "";
    this.expirationDate = "";
    this.description = "";
    this.comments = "";
    this.daysofWeeksetup.selectedList = [];
    this.selectedWeeks = [];
    this.selectedDays = [];
    this.pointCheckbox = false;
    this.qualifyCheckbox = false;
    this.noOfDayswithoutincident = null;
    this.pointsfordayswithoutincident = null;
    this.stepCheckbox = false;
    this.pointsPerYearsOfService = null;
    this.yearsOfService = null;
    this.warningStepPoints = null;
    this.pointsreducedafterone = null;
    this.emailstepone = null;
    this.dismissalStepPoints = null;
    this.pointsreducedaftertwo = null;
    this.emailsteptwo = null;
    this.pointsstepreachedthree = null;
    this.pointsreducedafterthree = null;
    this.emailstepthree = null;
    this.layoffCodeSetup.forEach(element => {
      element.availableList = element.availableList.concat(element.selectedList)
      element.selectedList = [];
      if (element.points) {
        element.points = null;
      }
      if (element.beforeOrAfterPoint) {
        element.beforeOrAfterPoint = null;
      }
    });
    this.dayOfWeekLayoffCodeSetup.forEach(element => {
      element.availableList = element.availableList.concat(element.selectedList)
      element.selectedList = [];
      if (element.points) {
        element.points = null;
      }

    });
    this.peakWeekLayoffCodeSetup.forEach(element => {
      element.availableList = element.availableList.concat(element.selectedList)
      element.selectedList = [];
      if (element.points) {
        element.points = null;
      }
    });
    this.holidayLayoffCodeSetup.forEach(element => {
      element.availableList = element.availableList.concat(element.selectedList)
      element.selectedList = [];
      if (element.points) {
        element.points = null;
      }
    });
    this.qualifyingLayoffSetupModel.qualifyingLayoffSetup.forEach(element => {
      element.availableList = element.availableList.concat(element.selectedList)
      element.selectedList = [];
      if (element.points) {
        element.points = null;
      }
    });
    this.pointRedcutionLayoffCodeSetup.forEach(element => {
      element.availableList = element.availableList.concat(element.selectedList)
      element.selectedList = [];
      if (element.points) {
        element.points = null;
      }
    });
  }

  public validateTime = (selectedTime, feild, index) => {
    if (selectedTime && (selectedTime.length == 4 || selectedTime.length == 5)) {

      selectedTime = selectedTime.replace(/[^0-9]+/g, '');
      let front = selectedTime.substring(0, 2);
      let end = selectedTime.substring(2, 4);
      if (front > 23 || front < 0 || (front.length > 1 && parseInt(front, 10) < 0)) {
        front = '00';
      }
      if (end > 59 || end < 0 || (front.length > 1 && parseInt(front, 10) < 0)) {
        end = '00';
      }
      if (end && end.length > 0) {
        this.dowConfig[index][feild] = front + ':' + end;
      } else {
        this.dowConfig[index][feild] = undefined;
      }

    }
  }


  public onCancel = () => {
    this.router.navigate(['/attendance']);
  }
  public onexportData = () => {
    let excelPoints = [];
    let excelDays = [];
    this.editResults.layoffCodeSetupVOs.forEach(element => {

      element.layoffCodesGroupVO.statusCodes.forEach(statuscode => {

        excelPoints.push(["", statuscode.statusCode + "(" + statuscode.description + ")"]);
      });

      excelPoints.push(["", "Points Assessed:" + element.layoffCodesGroupVO.points]);
      excelPoints.push(["", "Points Assessed Before or After Rest Day" + element.beforeAfterRestDayPoints]);
    });
    excelPoints.push([]);
    this.editResults.dayofWeekSetupVO.dayOfweekVOs.forEach(dayofweek => {
      excelDays.push(["", dayofweek.day]);
    })
    let excelAttendanceProfiles = [];
    excelAttendanceProfiles.push(["Profile ID:" + this.editResults.profileId]);
    excelAttendanceProfiles.push(["Description:" + this.editResults.description]);
    excelAttendanceProfiles.push(["Effective Date:" + moment(this.editResults.effectiveDate).format("YYYY-MM-DD")]);
    excelAttendanceProfiles.push(["Expiry Date:" + moment(this.editResults.expiryDate).format("YYYY-MM-DD")]);
    excelAttendanceProfiles.push([]);
    excelAttendanceProfiles.push(["Control Information"]);
    excelAttendanceProfiles.push(["Layoff Code Setup:"]);
    excelAttendanceProfiles.push(["Layoff Codes Selected:"]);
    excelPoints.forEach(point => {
      excelAttendanceProfiles.push(point);
    });
    excelAttendanceProfiles.push([]);
    excelAttendanceProfiles.push(excelDays);
    this.exportToExcel.exportAsExcelFile(excelAttendanceProfiles, 'Attendance Profile');
  }

  public generatePdf = () => {
    this.activatedRoute.params.subscribe(params => {
      this.editMode = true;
      this.messageObject = new MessageObject();
      this.apiservice.getMasterProfilePdf(params.oid).subscribe(response => {

        let file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        //window.open(fileURL);
        FileSaver.saveAs(file, "Attendance Profile");
        this.loading = false;
      },
        error => {
          this.messageObject.type = 'error';
          this.messageObject.systemMessage = error.systemMessage;
        });
    });
  }


}
