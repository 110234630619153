export class FeatureNames {
    public static pointReduction : string = 'Attendance Profiles - Point Reduction';
    public static qualifyingLayoff : string = 'Attendance Profiles - Qualifying';
    public static attendanceProfileBoardType : string = 'Attendance Profiles - Board Type';
    public static enableDashboardINDDSD : string = 'Enable Dashboard in DD/SD Report';
    public static enableHistoryInDDSD : string = 'Enable History in DD/SD Report';
    public static enableEmployeeSearchInMonitor : string = 'Enable Search in Monitor';
    public static updateEmployeeTEYFlagInHistory : string = 'Update Employee TEY Flag';
    public static enableFACTSDownload : string = 'Enable Facts PDF Download';
}
