export class ScreenNames {
    public static home = 'Home';
    public static attendanceProfiles = 'Attendance Profiles';
    public static globalAttributes = 'Global Properties'||'Application Attributes';
    // public static security = 'Security Profile';
    public static individualHistory = 'Individual History';
    public static individualSnapshot = 'Individual Snapshot';
    public static dismissals = 'Discipline Review Queue';
    public static districtSubdistrictSummary = 'District/Subdistrict Summary';
    public static calendarView = 'Calendar View';
    public static medicalQueue = 'Medical Queue';
    public static attendancePolicy = 'Attendance Policy';
    public static helpDocument = 'Help Document';
    public static apds = 'APDS';
    public static eams = 'EAMS';
    public static createAttendanceProfile = 'Attendance Profiles';
    public static manageAttendanceProfile = 'Attendance Profiles';
    public static disciplineReviewQueue = 'Discipline Review Queue';
    public static attendanceMonitor = 'Attendance Monitor';
    public static attendanceProfile = 'Attendance Profiles';
    public static security = 'Security Profiles';
    public static securityUsers = 'User Security';
    public static changeHistory = 'Change History';
    public static securityUser = 'User Security';
    public static securityrole = 'Security Profiles';
    public static craftMaster = 'Craft Master';
    // public static gobalProperties = 'Application Attributes';
    public static adminControl = ['Attendance Profiles','Global Properties','Application Attributes','Security Profiles','User Security','Change History'];
    public static reports = ['Individual History','Individual Snapshot','District/Subdistrict Summary','Calendar View'];
    public static toolkit = ['Attendance Policy','Help Document','APDS','EAMS'];
    public static boardType = 'Board Type';
    public static jobControl = 'Job Control';
    public static missedcallReview = 'Missedcall Review Queue';
    public static employeeMaster = 'Employee Master';
    public static disputeQueue = 'Challenge Review Queue';
    public static lateToReport = 'Late To Report';

}
