import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {  debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { SearchMasterProfileModel } from 'src/app/common/data/dataAPI/data-api.model';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.scss']
})
export class DistrictComponent implements OnInit {
  @Output() districtSharedChange = new EventEmitter<any>();
  @Input()  districtValue :string;
  @Input() isMultiple: boolean;
  displayData:any=[]
  messages: any[] = [];
  subscription: Subscription;
   districtRange: any
   
  public regiontypeaheadresponse: any = [];
  searchResults: SearchMasterProfileModel[] = []
  districtItems:  Observable<string[]>;
  public selectDistricts : string
  districtChange = new FormControl(["all"])
  distictSearch: any = [];
  employeeSearch: any;
  iconSearch = new FormControl();
  items: Observable<string[]>;
  districtTemplate = `<span>{{=result.districtName}} - {{=result.districtCode}}</span>`;
  public selectedDistrict: string;
  min=0;
  max:100;


  constructor(private districtservice:FiltersService,private apiservice:ReportsService) { }

  ngOnInit(): void {
    this.districtresetData()
    console.log("this.districtservice.getDistrictsData() ",this.districtservice.getDistrictsData());
    this.districtItems = this.iconSearch.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100),
      switchMap(value => this.districtservice.getDistrictsData())
    );
    console.log("ITEMS ",this.districtItems);
    this.getDistrictsData()
  }

  filterRows() {
    this.displayData = this.searchResults.filter(
      (item, index) => index >= this.min && index <= this.max
    );
  }
  getDistrictsData(){
    this.districtservice.getDistrictsData().subscribe((res:any) =>{
      this.regiontypeaheadresponse = res;
      let districtName = localStorage.getItem('districtName');
      if(this.districtValue){
		          districtName = this.districtValue;
		      }
      if(districtName) {
       const index =  this.regiontypeaheadresponse.findIndex((x: any) => x.districtName == districtName);
       if(index != -1) {
        this.districtRange = this.regiontypeaheadresponse[index].districtName + ' - ' + this.regiontypeaheadresponse[index].districtCode ;
        this.emitValue(this.regiontypeaheadresponse[index].districtCode)
       }
      }
    },error =>{

    })

  }

  districtData(event:any){
    this.districtValue = this.districtChange.value;
    this.emitValue(this.districtValue)

  }
  emitValue(value:any){
    this.districtSharedChange.emit(value)
  }
 
  selectDistrict(event: CustomEvent){
    event.detail.formatInput(item => item.districtName + ' - ' + item.districtCode );
    this.selectDistricts = event.detail.item.districtCode
    this.districtSharedChange.emit(this.selectDistricts);
  }
 
  changeValue(event: Event) {
    this.filterTypeAheads((event.target as HTMLInputElement).value);
    if(!event['data']) {
      this.districtSharedChange.emit(null)
    }
  }
  private filterTypeAheads(input: string = '') {
    this.districtItems = this.regiontypeaheadresponse.filter(i => i.districtName.toLowerCase().match(input.toLowerCase()));
    console.log("District ITems ",this.districtItems);
  }
  districtresetData(){
    this.subscription = this.apiservice.onMessage().subscribe(message => {
      if (message) {
          this.iconSearch.reset();
      } 
  });
  }
}
