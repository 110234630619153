import { Component, Input, Output, EventEmitter, OnInit, DoCheck, OnChanges } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import * as _ from 'lodash';

@Component({
	selector: 'pick-list',
	templateUrl: './pick-list.html',
	styleUrls: [
		'./pick-list.scss'
	]
})

export class PickListComponent implements OnInit, OnChanges {
	expandOnLoad: boolean;
	dropModelUpdated: any;
	dropModelId: any;
	highlightedItems: any;
	@Input('available-items') availableItems: any;
	@Output() availableItemsChange = new EventEmitter();

	@Input() selectedItems: any;
	@Output() selectedItemsChange = new EventEmitter();

	@Input('disable-pick-list') disablePickList: any;
	@Input('pick-list-config') pickListConfig: any;
	@Output('selected-item-value') selectedItemValue = new EventEmitter();
	@Input() formattedLabel = false;



	constructor(public dragulaService: DragulaService) {
		this.expandOnLoad = true;
		this.dropModelUpdated = false;
		this.highlightedItems = [];
		dragulaService.dropModel().subscribe((value) => {
			this.dropModelId = value[1].id;
			this.dropModelUpdated = true;
		});
	}
	ngOnChanges() {
		if (this.selectedItems == null) {
			this.selectedItems = [];
		}
		if (this.availableItems) {
			this.availableItems = _.sortBy(this.availableItems, [this.pickListConfig.id]);
		}
		if (this.selectedItems) {
			this.selectedItems = _.sortBy(this.selectedItems, [this.pickListConfig.id]);
		}
	}
	moveAllToSelected() {
		this.selectedItems = [...this.availableItems, ...this.selectedItems];
		this.availableItems = [];
		this.highlightedItems.map((item) => {
			item[this.pickListConfig.name + '-selected'] = false;
		});
		this.highlightedItems = [];
		this.selectedItems.map((item) => { // added to remove the added properties while submitting
			delete item[this.pickListConfig.name + '-selected'];
			delete item['movingFrom'];
		});
		if (this.availableItems) {
			this.availableItems = _.sortBy(this.availableItems, [this.pickListConfig.id]);
		}
		if (this.selectedItems) {
			this.selectedItems = _.sortBy(this.selectedItems, [this.pickListConfig.id]);
		}
		this.availableItemsChange.emit(this.availableItems);
		this.selectedItemsChange.emit(this.selectedItems);
	}

	moveAllToAvailable() {
		this.availableItems = [...this.availableItems, ...this.selectedItems];
		this.selectedItems = [];
		this.highlightedItems.map((item) => {
			item[this.pickListConfig.name + '-selected'] = false;
		});
		this.highlightedItems = [];
		this.availableItems.map((item) => { // added to remove the added properties while submitting
			delete item[this.pickListConfig.name + '-selected'];
			delete item['movingFrom'];
		});
		if (this.availableItems) {
			this.availableItems = _.sortBy(this.availableItems, [this.pickListConfig.id]);
		}
		if (this.selectedItems) {
			this.selectedItems = _.sortBy(this.selectedItems, [this.pickListConfig.id]);
		}
		this.availableItemsChange.emit(this.availableItems);
		this.selectedItemsChange.emit(this.selectedItems);

	}

	moveHighlightedToSelect() {
		this.highlightedItems = this.highlightedItems.map((item) => {
			delete item[this.pickListConfig.name + '-selected']; // = false;
			this.availableItems.forEach((value, key) => {
				if (item[this.pickListConfig.id] === value[this.pickListConfig.id]) {
					this.availableItems.splice(key, 1);
				}
			});
			return item;
		}).filter((item) => item['movingFrom'] === 'available').map((item) => { // added to remove the added properties while submitting
			delete item['movingFrom'];
			return item;
		});
		this.selectedItems = [...this.selectedItems, ...this.highlightedItems];
		this.highlightedItems = [];
		if (this.availableItems) {
			this.availableItems = _.sortBy(this.availableItems, [this.pickListConfig.id]);
		}
		if (this.selectedItems) {
			this.selectedItems = _.sortBy(this.selectedItems, [this.pickListConfig.id]);
		}
		this.availableItemsChange.emit(this.availableItems);
		this.selectedItemsChange.emit(this.selectedItems);
	}

	moveHighlightedToAvailable() {
		this.highlightedItems = this.highlightedItems.map((item) => {
			delete item[this.pickListConfig.name + '-selected']; // = false;
			this.selectedItems.forEach((value, key) => {
				if (item[this.pickListConfig.id] === value[this.pickListConfig.id]) {
					this.selectedItems.splice(key, 1);
				}
			});
			return item;
		}).filter((item) => item['movingFrom'] !== 'available').map((item) => { // added to remove the added properties while submitting
			delete item['movingFrom'];
			return item;
		});
		this.availableItems = [...this.availableItems, ...this.highlightedItems];
		this.highlightedItems = [];
		if (this.availableItems) {
			this.availableItems = _.sortBy(this.availableItems, [this.pickListConfig.id]);
		}
		if (this.selectedItems) {
			this.selectedItems = _.sortBy(this.selectedItems, [this.pickListConfig.id]);
		}
		this.availableItemsChange.emit(this.availableItems);
		this.selectedItemsChange.emit(this.selectedItems);
	}

	itemClick(item, type) {
		// /*if(type === 'available') {
		// 	this.availableItems.forEach((value,key)=>{
		// 		if(item[this.pickListConfig.id] === value[this.pickListConfig.id]) {
		// 			this.availableItems.splice(key,1);
		// 		}
		// 	});

		// 	this.selectedItems.push(item);
		// } else {
		// 	this.selectedItems.forEach((value,key)=>{
		// 		if(item[this.pickListConfig.id] === value[this.pickListConfig.id]) {
		// 			this.selectedItems.splice(key,1);
		// 		}
		// 	});

		// 	this.availableItems.push(item);
		// }*/

		//check the item is selected or not and add/remove selection class
		if (!item[this.pickListConfig.name + '-selected']) {
			item[this.pickListConfig.name + '-selected'] = true;
			item['movingFrom'] = type;
			this.highlightedItems.push(item);
		} else {
			item[this.pickListConfig.name + '-selected'] = false;
			this.highlightedItems.forEach((value, key) => {
				if (value[this.pickListConfig.id] === item[this.pickListConfig.id]) {
					this.highlightedItems.splice(key, 1);
				}
			});
		}

		//this.availableItemsChange.emit(this.availableItems);
		//this.selectedItemsChange.emit(this.selectedItems);

	}

	ngDoCheck() {
		if (this.dropModelUpdated) {
			const drake = this.dragulaService.find(this.pickListConfig.name).drake;
			const models = drake.models;
			const checkModelId = this.dropModelId;
			models[models.length - 1].map((model) => {
				if (model[this.pickListConfig.id] === checkModelId) {
					if (this.pickListConfig.emitEvent) {
						this.selectedItemValue.emit({ data: model, flag: true });
					}
					if (this.pickListConfig.name === 'codes-list') {
						model.isInherited = false;
					}
					this.dropModelId = '';
				}
			});
			models[models.length - 2].map((model) => {
				if (model[this.pickListConfig.id] === checkModelId) {
					if (this.pickListConfig.emitEvent) {
						this.selectedItemValue.emit({ data: model, flag: false });
					}
					this.dropModelId = '';
				}
			});
			this.dropModelUpdated = false;
		}
	}

	collapsedEvent(elementRef) {
		elementRef.expand = true;
	}

	ngOnInit() {
		// console.log(this.selectedItems);
	}
}

