import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedDataService } from './util/sharedData.service';
import { AuthorizationUtilService } from './util/authorization.util.service';
import { ScreenNames } from './screenNames';


@Injectable()
export class AuthGuardService implements CanActivate {
    public pageObject: any = null;
    constructor(private router: Router, private sharedDataService: SharedDataService, private authUtil: AuthorizationUtilService) {

    }
    canActivate(activatedRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean {
        this.sharedDataService.initialRoute = currentState.url;
        if (currentState.url === '/login') {
            if (sessionStorage.getItem('access-token') !== null) {
                this.router.navigate(['/home']);
            }
            return true;
        } else {
            if (sessionStorage.getItem('access-token') !== null) {
                const path = activatedRoute.routeConfig.path.split('/')[0];
                let capabilities = JSON.parse(sessionStorage.getItem('capabilities'));
                if (capabilities.length > 0 &&
                    this.authUtil.isAuthorized(ScreenNames[path], 'read')) {
                    this.sharedDataService.screenName = ScreenNames[path];
                    return true;
                } else {
                    return false;
                }
            } else {
                sessionStorage.removeItem('access-token');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('capabilities');
                localStorage.clear();
                this.sharedDataService.showMenu = false;
                this.router.navigate(['/login']);
                return false;
            }
        }
    }
}


