import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { CamsCommonModule } from './common/camsCommon.module';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { MatListModule } from '@angular/material';
import { MatSelectModule } from '@angular/material';
import {MatNativeDateModule} from "@angular/material/core";
import {FullCalendarModule} from "@fullcalendar/angular";
import {ScrollingModule} from '@angular/cdk/scrolling';
import { AppRoutingModule } from './app.routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { SideNavComponent } from './shared/side-nav/side-nav.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ManageAttendanceProfileComponent } from './dashboard/admin-control/attendance-profiles/manage-attendance-profile/manage-attendance-profile.component';
import { CreateAttendanceProfileComponent } from './dashboard/admin-control/attendance-profiles/create-attendance-profile/create-attendance-profile.component';
import { GlobalAttributesTableComponent } from './dashboard/admin-control/global-attributes/global-attributes-table/global-attributes-table.component';
import { AddGlobalAttributesComponent } from './dashboard/admin-control/global-attributes/add-global-attributes/add-global-attributes.component';
import { AddSecurityRolesComponent } from './dashboard/admin-control/security-profiles/add-security-roles/add-security-roles.component';
import { ApplicationAttributesFormComponent } from './dashboard/admin-control/application-attributes/application-attributes-form/application-attributes-form.component';
import { SecurityUsersAddComponent } from './dashboard/admin-control/security-users/security-users-add/security-users-add.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    ManageAttendanceProfileComponent,
    CreateAttendanceProfileComponent,
    AddSecurityRolesComponent,
    
    

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule,
    CamsCommonModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    DragulaModule,
    MatListModule,
    MatSelectModule,
    MatNativeDateModule,
    AppRoutingModule,
    SharedModule,
    RouterModule,
    FullCalendarModule,
    ScrollingModule
  ],
  providers: [DragulaService, DatePipe, {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { } 
