import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageObject, SecurityRolesVO, UserCapabilitiesModel } from 'src/app/common/data/dataAPI/data-api.model';
import { DataAPIService } from 'src/app/common/data/dataAPI/data-api.service';
import { UserModel } from 'src/app/common/models/UserModel';
import { ScreenNames } from 'src/app/common/screenNames';
import { AuthorizationUtilService } from 'src/app/common/util/authorization.util.service';
import { SharedDataService } from 'src/app/common/util/sharedData.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title = 'CAMS CSX';
  token: string;
  isModalOpen = false;
  selectedMenuItem: string;
  role: string;
  hideUi = false;
  public roleSwitchCapabilitiesResults: any;
  public userCapabilitiesResults: UserCapabilitiesModel = new UserCapabilitiesModel();
  public messageObject = new MessageObject();
  public screenNames = ScreenNames;
  // tslint:disable-next-line:max-line-length
  constructor(public sharedDataService: SharedDataService, private router: Router, private apiservice: DataAPIService, public authUtil: AuthorizationUtilService, private reportService: ReportsService) {
    if (environment.isUp) {
      sessionStorage.setItem('access-token', 'true');
      this.apiservice.upAuthenticate().subscribe(response => { AuthorizationUtilService
        if (response.data === 'OK') {
          this.hideUi = false;
          if (sessionStorage.getItem('access-token')) {
            this.token = sessionStorage.getItem('access-token');
            this.sharedDataService.allRoles = JSON.parse(sessionStorage.getItem('allRoles'));
            this.sharedDataService.showMenu = true;
            this.getcapabilities();
          }
        } else {
          sessionStorage.removeItem('user');
          this.hideUi = true;
          this.messageObject.type = 'error';
          this.messageObject.systemMessage = 'Not Authorized';
        }
      }, (error) => {
        sessionStorage.removeItem('user');
        this.messageObject.type = 'error';
        this.messageObject.systemMessage = 'Not Authorized';
        this.hideUi = true;
      });
    } else {
      if (sessionStorage.getItem('access-token')) {
        this.token = sessionStorage.getItem('access-token');
        this.sharedDataService.allRoles = JSON.parse(sessionStorage.getItem('allRoles'));
        this.sharedDataService.showMenu = true;
        this.getcapabilities();
      }
    }
  }

  ngOnInit(): void {
    
  }
  getcapabilities = () => {
    this.apiservice.userCapabilities().subscribe(response => {
      const user = {
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        username: response.data.defaultRole.roleName,
        roleOid : response.data.defaultSecurityRoleOid
      };
      sessionStorage.setItem('user', JSON.stringify(user));
      this.userCapabilitiesResults = response.data;
      this.sharedDataService.capabilities = this.userCapabilitiesResults.defaultRole.screenCapabilities;
      this.sharedDataService.featureCapabilities = this.userCapabilitiesResults.defaultRole.featureCapabilities;
      this.sharedDataService.menuList = this.userCapabilitiesResults.defaultRole.menuList;
      this.sharedDataService.allRoles = this.userCapabilitiesResults.securityRoles;
      sessionStorage.setItem('allRoles', JSON.stringify(this.userCapabilitiesResults.securityRoles));
      sessionStorage.setItem('capabilities', JSON.stringify(this.userCapabilitiesResults.defaultRole.screenCapabilities));
      // this.router.navigate([this.sharedDataService.initialRoute]);
    });
  }

logOut = () => {
	
	this.reportService.getOktaEnabled().subscribe(resp => {
			if(resp!=undefined && resp!=null && resp.length!=0 && resp[0].value=="Y"){
				this.sharedDataService.showMenu = false;
			    sessionStorage.removeItem('access-token');
			    sessionStorage.removeItem('allRoles');
			    sessionStorage.removeItem('user');  
				sessionStorage.clear();
				localStorage.clear();  
				this.apiservice.authenticateLogout({}).subscribe(response => {
		        	//window.location.href=response.data;
		        	window.location.href = "/login"
	      			this.router.navigate(['/login']);
	      		});
			}else{
				this.apiservice.logout().subscribe(response => {
      
    			});
				this.logoutActivity();
			}
	});
 }
	logoutActivity(){
		this.sharedDataService.showMenu = false;
	    if (!environment.isUp) { 
		  window.location.href = "/login"
	      this.router.navigate(['/login']);
	    }
	    sessionStorage.removeItem('access-token');
	    sessionStorage.removeItem('allRoles');
	    sessionStorage.removeItem('user');  
		sessionStorage.clear();    
	    if (environment.isUp) {
	      location.href = '/admin/logout.shtml';
	    }
	}
  
  getUserName = (): string => {
    let name = '';
    if (sessionStorage.getItem('user')) {
      const user: UserModel = <UserModel>JSON.parse(sessionStorage.getItem('user'));
      name = `${user.firstName} ${user.lastName} (${user.username})`;
    }
    return name;
  }
  setScreenName(menu: string){
    this.selectedMenuItem = menu;
  }

  getScreenName() {
    return this.selectedMenuItem;
  }




  updateOpen = (isOpen: boolean) => {
    this.isModalOpen = isOpen;
  }
  roleChange = () => {
	if(!this.role){
		return;
	}
    this.apiservice.roleSwitchCapabilities(this.role).subscribe(response => {
      this.sharedDataService.capabilities = response.screenCapabilities;
      this.sharedDataService.menuList = response.menuList;
      let user:any;
      if (sessionStorage.getItem('user')) {
        user = JSON.parse(sessionStorage.getItem('user'));
        const obj: SecurityRolesVO = _.find(this.sharedDataService.allRoles, (o: SecurityRolesVO) => {
          return o.roleOid === parseInt(this.role, 10);
        });
        if (obj) {
          user.username = obj.roleName;
          user.roleOid = obj.roleOid;
          sessionStorage.setItem('user', JSON.stringify(user));
        }
      }
      //sessionStorage.setItem('capabilities', JSON.stringify(this.sharedDataService.capabilities));
      this.role = undefined;
      this.updateOpen(false);
      
      if (user.username==='Craft') {
         this.router.navigateByUrl('/attendanceMonitor')
      }else if (this.sharedDataService.menuList.indexOf('Home') > -1) {
         this.router.navigateByUrl('/home')
      }else{
			let route = '/';
              Object.keys(ScreenNames).forEach(key => {
                if (ScreenNames[key] === this.sharedDataService.menuList[0]) {
                 route = route + key;
                }
              });
              this.router.navigate([route]);
		}
	    });
	  }
  public onHomeRefresh = () => {

  }
}
