import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter,debounceTime } from 'rxjs/operators';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss']
})
export class SupervisorComponent implements OnInit {
  @Output() supervisorValueChange = new EventEmitter<any>();
  @Input() supervisorValue: string;
  supervisorItems: Observable<string[]>;
  supervisor = new FormControl(["all"]);
  supervisorTemplate:any
  subscription: Subscription;
  iconSearch = new FormControl();
  supervisorRange:any;

  trainRange:any
  public selectSupervisor : string

  constructor(private supervisorservice: FiltersService,private apiSService:ReportsService) { }

  ngOnInit(): void {
    this.supervisorItems = this.iconSearch.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100),
    );

    this.getSupervisorData();
    this.supervisorresetData()
  }
  craftChange(event: any) {
    this.supervisorValue = this.supervisor.value;
    this.emitValue(this.supervisorValue);
  }
  emitValue(value: any) {
    this.supervisorValueChange.emit(value);
  }
  supervisorData(event: CustomEvent){
    event.detail.formatInput(item => item.Name);
    this.selectSupervisor = event.detail.item.Name
  }


  getSupervisorData() {
    /*this.supervisorservice.getSupervisor().subscribe((res: any) => {

    }, error => {

    })*/
  }
   changeValue(event: Event) {

  }
  supervisorresetData(){
    this.subscription = this.apiSService.onMessage().subscribe(message => {
      if (message) {
          this.iconSearch.reset();
      } 
  });
  }


}
