import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FiltersService } from '../../services/filters.service';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { SlowBuffer } from 'buffer';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-point-range',
  templateUrl: './point-range.component.html',
  styleUrls: ['./point-range.component.scss']
})
export class PointRangeComponent implements OnInit {
  messages: any[] = [];
  subscription: Subscription;
  seletedValue: any
  @Input() pointValue: string
  @Input() isMultiple: boolean;
  @Input() isHome: boolean;
  PointItem: Observable<string[]>;
  selectedPoints: any = [];
  @Output() pointChangeValue = new EventEmitter<any>();
  public pointRangesData: any = [];
  pointRange: any;
  iconSearch = new FormControl();
  pointTemplate = `<span>{{=result.value}}</span>`;
  selectedPointsMap: Map<any, boolean>;
  public selectedPointData = new FormControl();
  selectedPointRange: any;
  isUnit: boolean = false;

  constructor(private service: FiltersService, private apiservice: ReportsService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.pointresetData();
    this.pointRangesData = [];
    if(this.pointValue){
		      this.selectedPointRange =this.pointValue;
	    }
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.unit) {
        //this.selectedPointRange = params.unit;
        this.isUnit = true;
      }
      else {
        this.isUnit = false;
      }
    });
    this.getPointRangeData();
    this.PointItem = this.iconSearch.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100),
      switchMap(value => this.service.getPointRangeData())
    );
  }

  emitValue(event: any) {
    this.pointChangeValue.emit(event.value);
  }
  onChange(item, index) {
    this.selectedPointsMap.set(item.value, item.activeFlag);
    this.selectedPoints = [];
    this.selectedPointsMap.forEach((value, item) => {
      if (value) {
        this.selectedPoints.push(item);
      }
    });
    console.log("selectedPoints ", this.selectedPoints);
    this.pointChangeValue.emit(this.selectedPoints);

  }


  getPointRangeData() {
	this.selectedPoints = [];
    this.service.getPointRangeData().subscribe((res: any) => {
      this.selectedPointsMap = new Map();
      if (this.isHome) {
        if (this.selectedPointRange) {
          res.forEach(element => {
				element.activeFlag = false;
				for(let obj of this.selectedPointRange){
					if(element.value==obj){
						element.activeFlag = true;
						this.selectedPointsMap.set(element.value, true);
						this.selectedPoints.push(element.value)
					}
				}
          });
        }else{
			this.selectedPoints = res.map(x => {
	          this.selectedPointsMap.set(x.value, true);
	          return x.value;
	        });
		}
        
        this.pointChangeValue.emit(this.selectedPoints);
      }
      else {
        if (this.isUnit) {
          if (this.selectedPointRange) {
            console.log("SELECTED TANGE ",this.selectedPointRange)
            res.forEach(element => {
              element.activeFlag = false;
				for(let obj of this.selectedPointRange){
					if(element.value==obj){
						element.activeFlag = true;
						this.selectedPointsMap.set(element.value, true);
						this.selectedPoints.push(element.value)
					}
				}
            });
          }else{
				this.selectedPoints = res.map(x => {
			          this.selectedPointsMap.set(x.value, true);
			          return x.value;
			        });
			}
          console.log("SelectredPoints ",this.selectedPoints);
          this.pointChangeValue.emit(this.selectedPoints);
        }
        else {
          if (this.selectedPointRange) {
            console.log("Orioer ", res);
            res.forEach(element => {
              element.activeFlag = false;
				for(let obj of this.selectedPointRange){
					if(element.value==obj){
						element.activeFlag = true;
						this.selectedPointsMap.set(element.value, true);
						this.selectedPoints.push(element.value)
					}
				}
              console.log("ELement ", element);
            });
            this.pointChangeValue.emit(this.selectedPoints);
          }
          else {
            res.forEach(element => {
              element.activeFlag = true;
            });
            this.selectedPoints = res.map(x => {
              this.selectedPointsMap.set(x.value, true);
              return x.value;
            });
            this.pointChangeValue.emit([]);
          }
        }

      }
      this.pointRangesData = res;
    }, error => {

    });
  }

  changeValue(event: Event) {
    this.filterTypeAheads((event.target as HTMLInputElement).value);
  }

  private filterTypeAheads(input: string = '') {
    this.PointItem = this.pointRangesData.filter(i => i.value.toLowerCase().match(input.toLowerCase()));
    console.log('pointitems', this.PointItem)
  }
  pointresetData() {
    this.subscription = this.apiservice.onMessage().subscribe(message => {
      if (message) {
        this.iconSearch.reset();
      }
    });
  }

  reset(){
      this.pointresetData();
      this.getPointRangeData();
  }

}
