import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {  debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-craft',
  templateUrl: './craft.component.html',
  styleUrls: ['./craft.component.scss']
})
export class CraftComponent implements OnInit {
  @Output() craftValueChange = new EventEmitter<any>();
  @Input() craftValue: string;
  @Input() isMultiple: boolean;
  @Input() employeeTypeValue: string;
  subscription: Subscription;
  craftItems: Observable<string[]>;
  craft = new FormControl(["all"]);
  craftTemplate = `<span>{{=result.craftCode}}`;
  iconSearch = new FormControl();
  employeeSubsciption: Subscription;
  craftRange:any
  public selectedCraft : string
  public craftTypeaheadresponse:any = [];
  

  constructor(private craftservice: FiltersService,private apiSService:ReportsService) {
   }

  ngOnInit(): void {
    this.craftresetData();
    this.getCraftDataByEmployeeTypeSelection();
    this.craftItems = this.iconSearch.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100),
    );
    this.getCraftdata();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.employeeTypeValue) {
      console.log("employeeTypeValue ",this.employeeTypeValue)
    }
  }

  craftChange(event: any) {
    this.craftValue = this.craft.value;
    this.emitValue(this.craftValue);
  }
  emitValue(value: any) {
    this.craftValueChange.emit(value);
  }
 
  getCraftdata() {
    let body = [];
    this.craftservice.getCraftData(body).subscribe((response: any) => {
      this.craftTypeaheadresponse = response;
      let craftCode = localStorage.getItem('craftCode');
      if(this.craftValue){
		          craftCode = this.craftValue;
	      }
      if(craftCode) {
       const index =  this.craftTypeaheadresponse.findIndex((x: any) => x.craftCode == craftCode);
       if(index != -1) {
        this.craftRange = this.craftTypeaheadresponse[index].craftCode;
        this.emitValue(this.craftTypeaheadresponse[index].craftCode)
       }
      }
    }, error => {

    })
  }
   changeValue(event: Event) {
    this.filter((event.target as HTMLInputElement).value);
    if(!event['data']) {
      this.craftValueChange.emit(null)
    }
  }

  private filter(input: string = '') {
    this.craftItems = this.craftTypeaheadresponse.filter(i => i.craftCode.toLowerCase().match(input.toLowerCase()));
  }

  public onCraftChange = (value) => {
    if (value.target.value.length == 0){
      this.selectedCraft = null;
    }
  }

  selectCraft(event: CustomEvent) {
    event.detail.formatInput(item => item.craftCode);
    this.selectedCraft = event.detail.item.craftCode;
    this.craftValueChange.emit(this.selectedCraft);
  }
  craftresetData(){
    this.subscription = this.apiSService.onMessage().subscribe(message => {
      if (message) {
          this.iconSearch.reset();
      } 
  });
  }

  getCraftDataByEmployeeType(employeeType: any) {
    let body = []
    if(employeeType && employeeType != ''){
      body = [employeeType]
    }   
    this.craftservice.getCraftData(body).subscribe((response: any) => {
      this.craftTypeaheadresponse = response;
      const craftCode = localStorage.getItem('craftCode');
      if(craftCode) {
       const index =  this.craftTypeaheadresponse.findIndex((x: any) => x.craftCode == craftCode);
       if(index != -1) {
        this.craftRange = this.craftTypeaheadresponse[index].craftCode;
        this.emitValue(this.craftTypeaheadresponse[index].craftCode)
       }
      }
    }, error => {

    })
  }

  getCraftDataByEmployeeTypeSelection(){
    this.employeeSubsciption = this.craftservice.onEmployeeTypeData().subscribe(employeeType => {
      this.getCraftDataByEmployeeType(employeeType) 
  });
  }

}
