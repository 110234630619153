import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { SharedDataService } from '../common/util/sharedData.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router, public sharedDataService: SharedDataService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
	if(request.url.includes('assets')) {
      return next.handle(request);
    } else {
      let  restUrl;
      if(!request.url.includes(environment.restUrl)) {
        restUrl =  environment.restUrl + request.url;
      }
      request = request.clone({url: restUrl})
            const httpRequest = request.clone({
                headers: new HttpHeaders({
                    'Cache-Control': 'no-store, no-cache',
                    "Pragma": "no-cache",
                })
            });
      return next.handle(httpRequest).do((event: HttpEvent<any>) => {  
      if (event instanceof HttpResponse) {  
        // do stuff with response if you want  
      }  
    }, (err: any) => {  
      if (err instanceof HttpErrorResponse) {  
        if (err.status === 401 || err.status === 404) {  
			sessionStorage.clear();
			this.sharedDataService.sessionExpired=true;
			this.sharedDataService.showMenu=false;
        }
      }  
    }); 
    }
	
	
  }
}
