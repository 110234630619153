import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit {
  @Output() regionValueChange = new EventEmitter<any>();
  regionItems: Observable<string[]>;
  messages: any[] = [];
  subscription: Subscription;
  regionTemplate = `<span>{{=result.regionName}}`;
  selectedRegion: number = null;
  public regionSelection:string;
  iconSearch = new FormControl();
  public regiontypeaheadresponse:any = [];
  @Input() isMultiple: boolean;

  constructor(public apiService: ReportsService, private regionservice:FiltersService) { }

  ngOnInit(): void {
    this.roleresetData()
    this.regionItems = this.iconSearch.valueChanges.pipe(
      filter(x => x?.length > 1),
      debounceTime(100),
    );
    
    this.apiService.securityRegionTypeAheads().subscribe(response => {
      this.regiontypeaheadresponse = response;
    });
    
    // this.getRegionData()
  }
  selectRegion(event: CustomEvent) {
    event.detail.formatInput(item => item.regionName);
    this.selectedRegion = event.detail.item.regionName;
    this.regionValueChange.emit(this.selectedRegion);
  }
  changeValue(event: Event) {
    
    this.filter((event.target as HTMLInputElement).value);
}
emitValue(value: any) {
  this.regionValueChange.emit(value);
}
public onRegionChange = (value) => {
  if (value.target.value.length == 0){
    this.selectedRegion = null;
  }
}
private filter(input: string = '') {
  this.regionItems = this.regiontypeaheadresponse.filter(i => i.regionName.toLowerCase().match(input.toLowerCase()));
}
getRegionData(){
  this.regionservice.getRegionData().subscribe((res: any) => {

  }, error => {

  })
}
roleresetData(){
  this.subscription = this.apiService.onMessage().subscribe(message => {
    if (message) {
        this.iconSearch.reset();
    } 
});
}

}
