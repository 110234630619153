
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from 'src/app/common/endpoints';
import {CraftGroupsModel} from "../../../common/data/dataAPI/data-api.model";
@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    private subject = new Subject<any>()

    // GET_CRAFT_DATA_URL = `/secure/jas/services/crafts/getCraftCodesByCraftType`;
    GET_EMPLOYEE_TYPE_DATA = `/secure/jas/services/employeeTypes/getAllEmployeeTypes`;
    GET_DISTRICT_DATA_URL = `/get/district-data`;
    GET_POINT_RANGE_DATA_URL = `/secure/jas/services/applicationAttributes/getByType`;
    GET_SUB_DISTRICT_DATA_URL = `get/sub-district-data`;
    GET_TRAIN_MASTER_CODE=`/get/train-master`
    GET_SUPERVISOR_DATA=`/get/supervisor-data`
    GET_REGION_DATA=`/get/region-data`
    GET_DISTRICTS_DATA = `/secure/jas/services/districtOrSubDistrict/allDistricts`
    GET_SUB_DISTRICTS_DATA = `/secure/jas/services/districtOrSubDistrict/allSubDistricts?uniqueData=true`;


    constructor(private httpClient: HttpClient) { }

    getCraftData (body:any): Observable<any> {
        let endpoint = `${Endpoints.getCraftData}`;
        return this.httpClient.post<any>(endpoint,body).pipe(map(response =>{
            let items:any = response.data;
            return items;
        }))
    }

    getTrainData(){
        return this.httpClient.get(this.GET_TRAIN_MASTER_CODE)
    }
    getSupervisor(){
        return of();
    }
    getRegionData(){
        return this.httpClient.get(this.GET_REGION_DATA)
    }


    getEmployeeData =():Observable<CraftGroupsModel[]> => {
        return this.httpClient.get<any>(this.GET_EMPLOYEE_TYPE_DATA, {}).pipe(map(response =>{
            let employees :any = response.data;
            return employees
        }))
    }

    getPointRangeData =(): Observable<any> =>{
    return this.httpClient.post<any>(this.GET_POINT_RANGE_DATA_URL,{"type":"PointRange"}).pipe(map(response =>{
        let items:any = response.data;
        return items;
    }))
           

        
    }
    getSubDistrictData() {
        return this.httpClient.get(this.GET_SUB_DISTRICT_DATA_URL)
    }
    getDistrictsData =(): Observable<any> => {
        return this.httpClient.get<any>(this.GET_DISTRICTS_DATA).pipe(map(response =>{
            let items:any = response.data;
            return items;
        }))
    }
    
    public getSubdistrictData = (): Observable<any> => {
        return this.httpClient.get<any>(this.GET_SUB_DISTRICTS_DATA).pipe(map(response => {
          let subDistricts: any = response.data;
          return subDistricts;
        }))
      }

      onEmployeeTypeData(): Observable<any> {
        return this.subject.asObservable();
      }

      sendEmployeeTypeData(employeeType: any) {
        this.subject.next(employeeType);
      }

}
