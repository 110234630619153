export class UserModel {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    suffixCode: string;
    roleOid : number;
}
export class MessageObject {
    type = '';
    message = '';
    systemMessage = '';
    systemMessages = [];
}
export class UserCapabilityModel { }
