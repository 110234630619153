import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-sub-district',
  templateUrl: './sub-district.component.html',
  styleUrls: ['./sub-district.component.scss']
})
export class SubDistrictComponent implements OnInit {
  messages: any[] = [];
  subscription: Subscription;
  @Output() subdistrictSharedChange = new EventEmitter<any>();
  @Input() subdistrictValue: string;
  @Input() isMultiple: boolean;
  subdistrictChange = new FormControl(["all"]);
  subDistrictDta: any = []
  Search = new FormControl();
  selectedSubDistrict: any;
  subDistricts: Observable<string[]>;
  subDistrictTemplate = `<span>{{=result.subDistrictName}} - {{=result.subDistrictCode}}</span>`
  public selectedDistrict: string;
  subDistrict: any;
  subDistricttypeaheadresponse: any = [];

  constructor(private filterService: FiltersService,private apiservice:ReportsService) { }

  ngOnInit(): void {
    this.subdistructresetData()
    this.subDistricts = this.Search.valueChanges.pipe(
      filter(x => x.length > 1),
      debounceTime(100),
      switchMap(value => this.filterService.getSubdistrictData()),
      map(results => results.filter((i, idx) => idx < 15))
    )

    this.getSubdistrictData()

  }

  subdistrictData(event: any) {
    this.subdistrictValue = this.subdistrictChange.value;
    this.emitValue(this.subdistrictValue)

  }
  emitValue(value: any) {
    this.subdistrictSharedChange.emit(value)
  }
  getSubdistrictData() {
    this.filterService.getSubdistrictData().subscribe((res: any) => {
      this.subDistricttypeaheadresponse = res;
      const subDistrictName = localStorage.getItem('subDistrictName');
      if(subDistrictName) {
       const index =  this.subDistricttypeaheadresponse.findIndex((x: any) => x.subDistrictCode == subDistrictName);
       if(index != -1) {
        this.selectedSubDistrict = this.subDistricttypeaheadresponse[index].subDistrictCode + ' - ' + this.subDistricttypeaheadresponse[index].subDistrictCode ;
        this.emitValue(this.subDistricttypeaheadresponse[index].subDistrictCode)
       }
      }
    })
  }
  selectDistrict(event: CustomEvent) {
    event.detail.formatInput(item => item.subDistrictName + ' - ' + item.subDistrictCode);
    this.selectedDistrict = event.detail.item.subDistrictCode;
    this.subdistrictSharedChange.emit(this.selectedDistrict);
  }
  changeValue(event: Event) {
    this.filterTypeAheads((event.target as HTMLInputElement).value);
    if(!event['data']) {
      this.subdistrictSharedChange.emit(null)
    }
  }

  private filterTypeAheads(input: string = '') {
    this.subDistricts = this.subDistricttypeaheadresponse.filter(i => i.subDistrictName.toLowerCase().match(input.toLowerCase()));
  }
  subdistructresetData(){
    this.subscription = this.apiservice.onMessage().subscribe(message => {
      if (message) {
          this.Search.reset();
      } 
  });
  }

}
