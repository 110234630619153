import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataAPIService } from '../../../../common/data/dataAPI/data-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Region, SecurityRole, ServiceUnit, EmployeeSecurity, MessageObject, SearchUsersSecurity, SearchUsersSecurityResponse, PickListDaysModel, PickListRegionsModel, PickListRolesModel, PickListServiceUnitsModel } from '../../../../common/data/dataAPI/data-api.model';
import { AdminControlService } from '../../admin-control.service';
import { ReportsService } from '../../../reports/reports.service';
import { ScreenNames } from 'src/app/common/screenNames';
import { AuthorizationUtilService } from 'src/app/common/util/authorization.util.service';
import { FiltersService } from 'src/app/shared/filters/services/filters.service';

@Component({
  selector: 'app-security-users-add',
  templateUrl: './security-users-add.component.html',
  styleUrls: ['./security-users-add.component.scss']
})
export class SecurityUsersAddComponent implements OnInit {
  roleFormControl = new FormControl();
  regionFormControl = new FormControl();
  serviceUnitFormControl = new FormControl();
  public messageObject = new MessageObject();
  public passedEmployeeOid: number;
  public selectedRegions: [];
  public selectedOids = [];
  searchResults: EmployeeSecurity;
  roleItemsList = [];
  serviceUnitsItemsList = [];
  regionItemsList = [];
  public userDetails = [];
  employee = new EmployeeSecurity();
  public ScreenNames = ScreenNames;
  profileSaved: any;
  public serviceUnitsforRegionsResults: any;
  isUpdate: boolean = false;
  loading:boolean = false;
  public rolesPickListConfig = {
    id: 'roleName',
    value: 'roleName',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'layoffCodes',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };
  public suPickListConfig = {
    id: 'serviceUnitName',
    value: 'serviceUnitName',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'serviceUnitName',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };
  public regionPickListConfig = {
    id: 'regionName',
    value: 'regionName',
    availableListLabel: 'Available',
    selectedListLabel: 'Selected',
    name: 'layoffCodes',
    emitEvent: true,
    rank: 'rank',
    rankFlag: false
  };
  public editMode = false;
  selectedRoleList: any = [];
  availableRoleList: any = [];
  selectedRegionList: any = [];
  availableRegionList: any = [];
  availableSUList: any = [];
  selectedSUList: any = [];
  toppingList: string[] = ['User 1', 'User 2', 'User 3', 'User 4'];
  crafts: any = [];
  public selectedCraft: string   = '';
  public selectedUser = [];
  public selectedType = 'E';

  constructor(private router: Router,private service:ReportsService,public authUtil: AuthorizationUtilService, 
    private apiService: DataAPIService,private apiServices:AdminControlService, private activatedRoute: ActivatedRoute,private craftservice: FiltersService) {
      this.selectedType = 'E';
    }

  ngOnInit() {
    this.getCraftData();
    this.activatedRoute.params.subscribe(params => {
      const employee = new SearchUsersSecurityResponse();

      if(params.type) {
        if(params.type == 'user') {
          this.selectedType ='E';
          this.passedEmployeeOid = params.id;
          employee.employeeOid = params.id;
        }else if(params.type == 'craft'){
          this.selectedType ='C';
          employee.craftCode = params.id;
        }
      }
      if (params.action && params.action === 'edit') {
        this.isUpdate = true;
        this.searchEmployee(employee);
      } else if (params.action && params.action === 'copy') {
        if(employee.employeeOid){
          this.copyEmployee(employee.employeeOid,null);
        }else{
          this.copyEmployee(null,employee.craftCode);
        }
      }
    });
    this.apiServices.securityRoleTypeAheads().subscribe(response => {
      this.roleItemsList = response;
      this.roleItemsList.forEach(e => delete e.description);
      this.availableRoleList = _.cloneDeep(this.roleItemsList);
    });
    // this.apiService.securityServiceUnitTypeAheads().subscribe(response => {
    //   this.serviceUnitsItemsList = response;
    //   this.availableSUList = _.cloneDeep(response);
    // });
    this.apiServices.securityRegionTypeAheads().subscribe(response => {
      this.regionItemsList = response;
      this.availableRegionList = _.cloneDeep(response);
    });
  }
  public constructPayload() {
    this.employee.employeeOid = this.passedEmployeeOid;
    this.employee.craftCode = this.selectedCraft;
    this.employee.securityRoles = this.selectedRoleList;
    this.employee.serviceUnits = this.selectedSUList;
    this.employee.regions = this.selectedRegionList;
    return this.employee;
  }

  public onClickOfSave() {
    const payLoad = this.constructPayload();
    this.messageObject = new MessageObject();
    if (this.isUpdate) {
      this.updateUserProfile(payLoad);
    } else {
      this.saveUserProfile(payLoad);
    }
  }

  public saveUserProfile(payLoad: any) {
    this.loading = true;
    this.apiService.saveUserProfile(payLoad).subscribe(response => {
          this.profileSaved = response;
          this.messageObject.type = response.result.toLowerCase();
          this.messageObject.systemMessage = response.messages[0].systemMessage;
          window.scrollTo(0, 0);
          setTimeout(() => {
            if (response.result == 'SUCCESS') {
              this.router.navigate(['securityUsers']);
            }
          }, 1000);
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.messageObject.type = 'error';
          this.messageObject.systemMessage = error.systemMessage;
        });
  }

  public updateUserProfile(payLoad: any) {
    this.loading = true;
    this.apiService.updateUserProfile(payLoad).subscribe(response => {
          this.profileSaved = response;
          this.messageObject.type = response.result.toLowerCase();
          this.messageObject.systemMessage = response.messages[0].systemMessage;
          window.scrollTo(0, 0);
          setTimeout(() => {
            if (response.result == 'SUCCESS') {
              this.router.navigate(['securityUsers']);
            }
          }, 1000);
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.messageObject.type = 'error';
          this.messageObject.systemMessage = error.systemMessage;
        });
  }
  public searchEmployee = (employee) => {
    this.loading = true;
    this.apiService.securityUserEditPageLoadData(employee).subscribe(response => {
      this.searchResults = response.data;
      this.employee.firstName = this.searchResults.firstName;
      this.employee.lastName = this.searchResults.lastName;
      this.employee.middleName = this.searchResults.middleName;
      this.employee.employeeId = this.searchResults.employeeId;
      this.employee.craftCode = this.searchResults.craftCode;
      this.selectedCraft = this.searchResults.craftCode;
      this.employee.defaultSecurityRoleOid = this.searchResults.defaultSecurityRoleOid;
      this.messageObject.type = response.result.toLowerCase();
      this.messageObject.systemMessage = response.messages[0].systemMessage;
      this.convertToSlectedRegions();
      this.convertToSlectedRoles();
      this.convertToSlectedserviceUnits();
      // if (this.searchResults) {
      //   this.messageObject.type = 'error';
      //   this.messageObject.systemMessage = "No results found."
      // }
      if(this.selectedType == 'E'){
        this.loading = false;
      }
    },
      error => {
        this.loading = false;
        this.messageObject.type = 'error';
        this.messageObject.systemMessage = error.systemMessage;
      });
  }
  public copyEmployee = (employee: number,craftCode : string) => {
    this.loading = true;
    this.apiService.userCopy(employee,craftCode).subscribe(response => {
      this.searchResults = response.data;
      this.passedEmployeeOid= undefined;
      this.employee.defaultSecurityRoleOid = this.searchResults.defaultSecurityRoleOid;
      this.convertToSlectedRegions();
      this.convertToSlectedRoles();
      this.convertToSlectedserviceUnits();
      this.messageObject.type = response.result.toLowerCase();
      this.messageObject.systemMessage = response.messages[0].systemMessage;
      this.loading = false;
    });
  }
  private convertToSlectedRegions = () => {
    this.selectedRegionList = this.searchResults.regions;
    this.availableRegionList = _.differenceWith(this.availableRegionList, this.selectedRegionList, _.isEqual);
  }
  private convertToSlectedserviceUnits = () => {
    this.selectedSUList = this.searchResults.serviceUnits;
    this.availableSUList = _.differenceWith(this.availableSUList, this.selectedSUList, _.isEqual);
  }
  private convertToSlectedRoles = () => {
    this.searchResults.securityRoles.forEach(e => delete e.description);
    this.selectedRoleList = this.searchResults.securityRoles;
    this.availableRoleList = _.differenceWith(this.availableRoleList, this.selectedRoleList, _.isEqual);
  }
  public onCancelClick = () => {
    this.router.navigate(['/security']);
  }
  public regionSelected = (selectedRegions) => {
    this.selectedOids = [];
    this.availableSUList = [];
    this.selectedSUList = [];
    selectedRegions.forEach(region => {
      this.selectedOids.push(region.regionOid);
    });
    if (this.selectedOids.length > 0) {
      this.service.serviceUnitsforRegions(this.selectedOids).subscribe(response => {
        this.availableSUList = _.cloneDeep(response);
      });
    }

  }

  getCraftData() {
    let body = [];
    this.loading = true;
    this.craftservice.getCraftData(body).subscribe((response: any) => {
      this.crafts = this.filterAllNONCrafts(response);
      this.loading = false; 
    }, error => {
      this.loading = false; 
    })
  }
   filterAllNONCrafts(crafts: any): string[] {
    crafts = crafts.filter(value =>( value.craftCode === 'NON-Manager' || !value.craftCode.startsWith('NON')));
    return crafts;
  }
}
