import { NgModule } from '@angular/core';
import { DataModule } from './data/data.module';
import { SharedDataService } from './util/sharedData.service';
import { AuthGuardService } from './auth-guard.service';
@NgModule({
    declarations: [],
    imports: [DataModule],
    providers: [SharedDataService, AuthGuardService]
})
export class CamsCommonModule { }
