import { Component, OnInit } from '@angular/core';
import { MessageObject, ApplicationAttributeVO } from 'src/app/common/data/dataAPI/data-api.model';
import { DataAPIService } from 'src/app/common/data/dataAPI/data-api.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-properties-form',
  templateUrl: './global-properties-form.component.html',
  styleUrls: ['./global-properties-form.component.scss']
})
export class GlobalPropertiesFormComponent implements OnInit {

  public globalPropertiesSaveDetails: ApplicationAttributeVO = new ApplicationAttributeVO;
  public messageObject = new MessageObject();
  public editMode = false;
  public pointRangeResults: any;
  public globalPropertiesSaveResults: ApplicationAttributeVO;
  public type: string = 'PointRange';
  public boardType: string = 'Board Type Code';
  public pointAdjustment: string = 'Point Adjustments';

  public typeCode: string ='';
  constructor(private apiservice: DataAPIService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.typeCode =params.type;
      if (params.oid) {
        this.editMode = true;
        this.apiservice.getglobalPropertiesEdit(params.oid).subscribe(response => {
          this.globalPropertiesSaveDetails = response.data;
        });
      }
    });

  

   /* this.apiservice.getglobalProperties(this.typeCode).subscribe(response => {
      this.pointRangeResults = response;
      if (this.pointRangeResults && this.pointRangeResults[0]) {
        this.globalPropertiesSaveDetails.type = this.pointRangeResults[0].type;
      }
    });*/
   
    if(this.typeCode === this.type){
      this.globalPropertiesSaveDetails.type = this.type;

    }
    else if(this.typeCode === this.boardType){
      this.globalPropertiesSaveDetails.type = this.boardType;
     }
     else if(this.typeCode === this.pointAdjustment){
      this.globalPropertiesSaveDetails.type = this.pointAdjustment;
     }

  }
  public onClickofSave = () => {
    if (this.editMode) {
      this.apiservice.globalPropertiesUpdate(this.globalPropertiesSaveDetails).subscribe(response => {
        this.globalPropertiesSaveResults = response.data;
        if (response.messages) {
          this.messageObject.systemMessages = response.messages;
        }
        if (response.result) {
          this.messageObject.type = response.result.toLowerCase();
        }
        window.scrollTo(0, 0);
      });
    }
    else {
      this.apiservice.globalPropertiesSave(this.globalPropertiesSaveDetails).subscribe(response => {
        this.globalPropertiesSaveResults = response.data;
        if (response.messages) {
          this.messageObject.systemMessages = response.messages;
        }
        if (response.result) {
          this.messageObject.type = response.result.toLowerCase();
        }
        window.scrollTo(0, 0);
      });
    }
  }
  public onCancel = () => {
    this.router.navigate(['/gloabal-properties']);

  }
}

