import { Injectable } from '@angular/core';
import { FeatureRoleVO, screenCapabilitiesVO, SecurityRolesVO } from '../data/dataAPI/data-api.model';

@Injectable()
export class SharedDataService {
    constructor() { }
    // public menuItems: MenuItem = [];
    public showMenu = false;
    public capabilities: screenCapabilitiesVO[] = [];
    public allRoles: SecurityRolesVO[] = [];
    public featureCapabilities: FeatureRoleVO[] = [];
    public menuList: string[] = [];
    public regions: string[] = [];
    public screenName: string = 'Login';
    public serviceUnitSearchParams: any;
    public initialRoute: string;
    public isUpPortal : Boolean = true;
    public sessionExpired: Boolean = false;
}
