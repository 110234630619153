import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { DismissalReviewQueueSearchModel, MessageObject } from 'src/app/common/data/dataAPI/data-api.model';
import { AdminControlService } from 'src/app/dashboard/admin-control/admin-control.service';
import { DisciplineReviewQueueService } from 'src/app/dashboard/discipline-review-queue/discipline-review-queue.service';
import { ReportsService } from 'src/app/dashboard/reports/reports.service';
import { FiltersService } from '../filters/services/filters.service';


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  @Output() subemployeeSharedChange = new EventEmitter<any>();
  @Input() subemployeeValue: string;
  @Input() styles: string;
  public employeeOid: number = null;
  private employee: string = "";
  @Input() sharedEmployee: any;
  @Input() isTey: boolean;
  @Output() sharedEmployeeChange =  new EventEmitter<any>();
  public searchHide = false;
  selectedEmployee: number = null;
  @Input() isMultiple: boolean;
  employeeTemplate = `<span>{{=result.name}} - {{=result.employeeId}}</span>`;
  public region: string = "";
  public serviceUnit: string = "";
  public regionSelected: string = "";
  public serviceUnitSelected: number;
  public selectedUserId: any;
  public loadingMessage = '';
  public loading = false;
  public messageObject = new MessageObject();
  public showtable = false;
  messages: any[] = [];
  subscription: Subscription;
  min = 0;
  max = 100;
  displayData = [];
  userItems: Observable<string[]>;
  regionItems: string[];
  public regiontypeaheadresponse: any = [];
  serviceUnitItems: string[];
  public serviceunittypeaheadresponse: any = [];
  
  public dismissalreviewqueuesearchResults: DismissalReviewQueueSearchModel[];

  iconSearch = new FormControl();

  constructor(public route : ActivatedRoute, public apiService: DisciplineReviewQueueService, private apisservices:AdminControlService, private filterservice: FiltersService,private apiSService:ReportsService) { }
  
  ngOnInit(): void {
    this.employeeresetData()
  
    this.userItems = this.iconSearch.valueChanges.pipe(
      filter(x => x?.length > 1),
      debounceTime(100),
      switchMap(value => this.apisservices.nonTEYEmployeeTypeAheads(value, this.isTey)),
      map(results => results.filter((i, idx) => idx < 15))
    );

    
   
    this.route.params.subscribe(params => {
      if (params.employeeOid) {
        this.employeeOid = params.employeeOid;
        this.onClickofSearch();
      }
    })
    console.log("sharedEmployee In Component ",this.sharedEmployee);
  }


  
  public onClickofSearch = () => {
    if (this.employee == "" ) {
      this.employeeOid = null;
    }
    if (this.region == "") {
      this.regionSelected = "";
    }
    if (this.serviceUnit == "") {
      this.serviceUnitSelected = null;
    }
    this.searchHide = true;
    this.loadingMessage = 'Loading';
    this.loading = true;
    this.messageObject = new MessageObject();
    this.apiService.dismissalreviewqueueSearch(this.employeeOid).subscribe(response => {
      this.dismissalreviewqueuesearchResults = response.data;
      this.loading = false;
      this.filterRows();
      this.showtable = true;
      this.messageObject.type = response.result.toLowerCase();
      this.messageObject.systemMessage = response.messages[0].systemMessage;
    },
      error => {
        this.messageObject.type = 'error';
        this.messageObject.systemMessage = error.systemMessage;
      });
  };
  filterRows() {
    this.displayData = this.dismissalreviewqueuesearchResults.filter(
      (item, index) => index >= this.min && index <= this.max
    );
  }
  onChange(event: Event){
    this.sharedEmployee = this.iconSearch.value;
    console.log("SharedEEMployee ",this.sharedEmployee);
    this.emitValue(this.sharedEmployee);
    this.filterTypeAheads((event.target as HTMLInputElement).value);
  }
  private filterTypeAheads(input: string = '') {
    this.regionItems = this.regiontypeaheadresponse.filter(i => i.regionName.toLowerCase().match(input.toLowerCase()));
    this.serviceUnitItems = this.serviceunittypeaheadresponse.filter(i => i.serviceUnitName.toLowerCase().match(input.toLowerCase()));
    if (this.regionItems.length == 0) {
      this.regionSelected = "";
    }
    if (this.serviceUnitItems.length == 0) {
      this.serviceUnitSelected = null;
    }
  }

  emitValue(value: any) {
    this.sharedEmployeeChange.emit(value);
  }
  public onEmployeeChange = (value) => {
    this.selectedUserId;
    if (value.target.value.length == 0){
      this.selectedEmployee = null;
      this.userItems  = null;
      this.iconSearch = null;
    }

  }
  // onEmployeeChange(event:Event){
  //   this.selectedUserId;

  //   this.filterTypeAheads((event.target as HTMLInputElement).value);

  //   if(!event['data']) {
  //     this.subemployeeSharedChange.emit(null)
  //   }
  // }
  selectEmployee(event: CustomEvent) {
    event.detail.formatInput(item => item.name + ' - ' + item.employeeId);
    this.selectedEmployee = event.detail.item.employeeOid;
    this.subemployeeSharedChange.emit(this.selectedEmployee);
  }
  employeeresetData(){
    this.subscription = this.filterservice.onEmployeeTypeData().subscribe(employeetype => {
      if (employeetype) {
        this.iconSearch.reset();
      } 
  });
  }
 

}
