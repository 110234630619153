import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureRoleVO, SecurityRoleVO, SecurityScreenCapabilityVO } from 'src/app/common/data/dataAPI/data-api.model';
import { DataAPIService } from 'src/app/common/data/dataAPI/data-api.service';
import { MessageObject } from 'src/app/common/models/UserModel';
import { ScreenNames } from 'src/app/common/screenNames';
import { AuthorizationUtilService } from 'src/app/common/util/authorization.util.service';


@Component({
  selector: 'app-add-security-roles',
  templateUrl: './add-security-roles.component.html',
  styleUrls: ['./add-security-roles.component.scss']
})
export class AddSecurityRolesComponent implements OnInit {
  public loadingMessage = 'Loading';
  public loading = false;
  public ScreenNames = ScreenNames;
  public editMode = false;
  public roleDetails: SecurityRoleVO = new SecurityRoleVO();
  public securityRolesAddresults: any;
  public allScreenNames: any[] = [];
  public allFeatureNames: any[] = [];
  public screenType: string = 'Screen';
  public featureType: string = 'Feature';
  public messageObject = new MessageObject();
  selectAllRead: boolean;
  selectAllCreate: boolean;
  selectAllUpdate: boolean;
  selectAllDelete: boolean;
  selectAllEnable: boolean;
  constructor(private apiService: DataAPIService, private activatedRoute: ActivatedRoute, private router: Router,public authUtil: AuthorizationUtilService,) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.action && params.action === 'copy' && params.roleOid) {
        this.loading = true;
        this.apiService.getglobalProperties(this.screenType).subscribe(allScreenresponse => {
          this.allScreenNames = allScreenresponse;
          this.apiService.roleCopy(params.roleOid).subscribe(response => {
            this.roleDetails = response.data;
            const availableScreenNames: String[] = [];
            this.roleDetails.screenCapabilities.forEach(element => {
              availableScreenNames.push(element.screenName);
            });
            this.allScreenNames.forEach(element => {
              if (!availableScreenNames.includes(element.name)) {
                const obj = new SecurityScreenCapabilityVO();
                obj.screenName = element.name;
                obj.applicationAttributeOid = element.oid;
                this.roleDetails.screenCapabilities.push(obj);
              }
            });
            this.initializeScreenSelectAllCheckBoxes();
            this.apiService.getglobalProperties(this.featureType).subscribe(allFeatures => {
              this.convertToFeatureModel(allFeatures,this.roleDetails.featureCapabilities);
              this.loading = false;
            },error => {
              this.messageObject.type = 'error';
              this.messageObject.systemMessage = error.systemMessage;
              this.loading = false;
            });
          },error => {
            this.messageObject.type = 'error';
            this.messageObject.systemMessage = error.systemMessage;
            this.loading = false;
          });
        });
      }
      if (params.roleOid && params.action && params.action === 'edit') {
        this.editMode = true;
        this.loading = true;
        this.apiService.getglobalProperties(this.screenType).subscribe(allScreenresponse => {
          this.allScreenNames = allScreenresponse;
          this.apiService.securityeditRole(params.roleOid).subscribe(response => {
            this.roleDetails = response.data;
            const availableScreenNames: String[] = [];
            this.roleDetails.screenCapabilities.forEach(element => {
              availableScreenNames.push(element.screenName);
            });
            this.allScreenNames.forEach(element => {
              if (!availableScreenNames.includes(element.name)) {
                const obj = new SecurityScreenCapabilityVO();
                obj.screenName = element.name;
                obj.applicationAttributeOid = element.oid;
                this.roleDetails.screenCapabilities.push(obj);
              }
            });
            this.initializeScreenSelectAllCheckBoxes();
            this.apiService.getglobalProperties(this.featureType).subscribe(allFeatures => {
              this.convertToFeatureModel(allFeatures,this.roleDetails.featureCapabilities);
              this.loading = false;
            },error => {
              this.messageObject.type = 'error';
              this.messageObject.systemMessage = error.systemMessage;
              this.loading = false;
            });
          },error => {
            this.messageObject.type = 'error';
            this.messageObject.systemMessage = error.systemMessage;
            this.loading = false;
          });
        });
      }
      else {
        this.apiService.getglobalProperties(this.screenType).subscribe(response => {
          this.securityRolesAddresults = response;
          this.convertToRoleModel();
        });
        this.apiService.getglobalProperties(this.featureType).subscribe(allFeatures => {
          this.convertToFeatureModel(allFeatures,[]);
        });
      }
    });
  }
  private convertToFeatureModel = (allFeatures,features : FeatureRoleVO[]) => {
    const availableFeatureNames: String[] = [];
    features.forEach(element => {
      availableFeatureNames.push(element.featureName);
    });
    allFeatures.forEach(element => {
      if (!availableFeatureNames.includes(element.name)) {
        const obj = new FeatureRoleVO();
        obj.featureName = element.name;
        obj.applicationAttributeOid = element.oid;
        obj.isEnabled = false;
        features.push(obj);
      }
    });
    features.filter(i => !i.isEnabled).length > 0 ? this.selectAllEnable = false : this.selectAllEnable = true;
    this.roleDetails.featureCapabilities = features;
  }
  private convertToRoleModel = () => {
    this.roleDetails.screenCapabilities = [];
    this.securityRolesAddresults.forEach(element => {
      const obj = new SecurityScreenCapabilityVO();
      obj.screenName = element.name;
      obj.applicationAttributeOid = element.oid;
      this.roleDetails.screenCapabilities.push(obj);
    });
  }
  public onClickofSave = () => {
    this.loading = true;
    if (this.editMode) {
      this.apiService.updateSecurityRole(this.roleDetails).subscribe(response => {
        this.messageObject.type = response.result.toLowerCase();
        this.messageObject.systemMessage = response.messages[0].systemMessage;
        window.scrollTo(0, 0);
        this.loading = false;
        setTimeout(() => {
          if (response.result == 'SUCCESS') {
            this.router.navigate(['security']);
          }
        }, 3000);
      },error => {
        this.loading = false;
        this.messageObject.type = 'error';
        this.messageObject.systemMessage = error.systemMessage;
      });
    } else {
      this.apiService.saveSecurityRole(this.roleDetails).subscribe(response => {
        if (response.result) {
          this.messageObject.type = response.result.toLowerCase();
          this.messageObject.systemMessage = response.messages[0].systemMessage;
        }
        this.loading = false;
        window.scrollTo(0, 0);
        setTimeout(() => {
          if (response.result == 'SUCCESS') {
            this.router.navigate(['security']);
          }
        }, 3000);
      },error => {
        this.loading = false;
        this.messageObject.type = 'error';
        this.messageObject.systemMessage = error.systemMessage;
      });
    }
  }
  public selectAllCapability = (value: boolean, type: string) => {
    this.roleDetails.screenCapabilities.forEach(element => {
      if (type == 'read') {
        element.read = value;
      } else if (type == 'create') {
        element.create = value;
      } else if (type == 'update') {
        element.update = value;
      } else if (type == 'delete') {
        element.delete = value;
      }
    });
  }

  public selectAllFeature = (value: boolean, type: string) => {
    this.roleDetails.featureCapabilities.forEach(element => {
      element.isEnabled = value;
    });
  }

  public initializeScreenSelectAllCheckBoxes = () => {
    this.roleDetails.screenCapabilities.filter(i => !i.read).length > 0 ? this.selectAllRead = false : this.selectAllRead = true;
    this.roleDetails.screenCapabilities.filter(i => !i.create).length > 0 ? this.selectAllCreate = false : this.selectAllCreate = true;
    this.roleDetails.screenCapabilities.filter(i => !i.update).length > 0 ? this.selectAllUpdate = false : this.selectAllUpdate = true;
    this.roleDetails.screenCapabilities.filter(i => !i.delete).length > 0 ? this.selectAllDelete = false : this.selectAllDelete = true;
  }
  public onCancelClick = () => {
    this.router.navigate(['/security-profiles']);

  }
}
