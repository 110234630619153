import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DismissalreviewqueueModel, SendToAPDSResponseModel, SendTOAPDSVO } from 'src/app/common/data/dataAPI/data-api.model';
import { Endpoints } from 'src/app/common/endpoints';

@Injectable({
  providedIn: 'root'
})
export class DisciplineReviewQueueService {

  public  dismissalReviewQueuePdfGenerationUrl = '/secure/jas/services/pdf/dissmissalReviewQueuePdf';
  public  dismissalreviewqueueDeleteUrl = '/secure/jas/services/dismissalReviewQueue/delete';
  public  sendToAPDSUrl = '/secure/jas/services/employeeDiscipline/sendToDisciplinarySystem';
  public  downloadFACTSPdfSUrl = '/secure/jas/services/dismissalReviewQueue/generateFACTSPdf';
  public  serviceUnitsforRegionsUrl = '/secure/jas/services/location/getServiceUnitsForRegions';
  public  employeeTypeAheadsUrl = '/secure/jas/services/employee/getEmployeeNameAndId';
  public  dismissalreviewqueueSearchUrl = '/secure/jas/services/dismissalReviewQueue/search';


  constructor(private httpClient:HttpClient) { }

  public dismissalreviewqueueSearch = (body:any): Observable<DismissalreviewqueueModel> => {
    //  let endpoint = `${Endpoints.dismissalreviewqueueSearch}

    return this.httpClient.post<any>(this.dismissalreviewqueueSearchUrl,body).pipe(map(response => {
      let dismissalreviewqueuesearchResults: DismissalreviewqueueModel = new DismissalreviewqueueModel;
      dismissalreviewqueuesearchResults.data = response.data;
      dismissalreviewqueuesearchResults.messages = response.messages;
      dismissalreviewqueuesearchResults.result = response.result;
      return dismissalreviewqueuesearchResults;
    }))
  }

  public dismissalreviewqueuePdf = (body:any): Observable<Blob> => {

    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };

    return this.httpClient.post<Blob>(this.dismissalReviewQueuePdfGenerationUrl, body, httpOptions);
  }
  public dismissalReviewDelete = (oid: number): Observable<any> => {
    return this.httpClient.post<any>(this.dismissalreviewqueueDeleteUrl + "?oid=" + oid, {});
  }
  public sendToAPDS = (selectedApdsRecords: any): Observable<Blob> => {
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };
    return this.httpClient.post<Blob>(this.sendToAPDSUrl, selectedApdsRecords,httpOptions);
  }
  public downloadFACTSPdf = (selectedApdsRecords: any): Observable<Blob> => {
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };
    return this.httpClient.post<Blob>(this.downloadFACTSPdfSUrl, selectedApdsRecords,httpOptions);
  }
  public securityDistinctServiceUnitTypeAheads = (): Observable<any> => {
    let endpoint = Endpoints.securityDistinctServiceUnitTypeAheads;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceunittypeaheadresponse: any = response.data;
      return serviceunittypeaheadresponse;
    }))
  }
  public getFACTSReportStartDate = (employeeOid :any ): Observable<any> => {
    let endpoint =`${Endpoints.getFACTSReportStartDate}/${employeeOid}`
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let factsStartDate: any = response.data;
      return factsStartDate;
    }))
  }
  public serviceUnitsforRegions = (regionOids: any) => {
    return this.httpClient.get<any>(this.serviceUnitsforRegionsUrl).pipe(map(response => {
      let serviceUnitsforRegionsResults = response.data;
      return serviceUnitsforRegionsResults;
    }));
  }
  public securityRegionTypeAheads = (): Observable<any> => {
    let endpoint = Endpoints.securityRegionTypeAheads;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let regiontypeaheadresponse: any = response.data;
      return regiontypeaheadresponse;
    }))
  }
  public securityServiceUnitTypeAheads = (): Observable<any> => {
    let endpoint = Endpoints.securityServiceUnitTypeAheads;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceunittypeaheadresponse: any = response.data;
      return serviceunittypeaheadresponse;
    }))
  }
  public employeeTypeAheads = (startingWith: string): Observable<any> => {
    return this.httpClient.get<any>(this.employeeTypeAheadsUrl).pipe(map(response => {
      let typeaheadresponse: any = response.data;
      return typeaheadresponse;
    }))
  }


}
