import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SharedDataService } from './sharedData.service';
import { FeatureRoleVO, screenCapabilitiesVO } from '../data/dataAPI/data-api.model';


@Injectable(
    { providedIn: 'root' }
)
export class AuthorizationUtilService {
  
    constructor(private sharedService: SharedDataService) { }
    selectedMenuItem: string;
    public isAuthorized = (capability1: string, action: string): boolean => {
        let boolean = false;
        let capabilities = JSON.parse(sessionStorage.getItem('capabilities'));
        let obj = _.find(capabilities, (o: screenCapabilitiesVO) => {
            return o.screenName == capability1;
        });
        if (obj) {
            return obj[action];
        }
        return boolean;
    }
    public hasAllcapabilities = (capability1: string): boolean => {
        let capabilities = JSON.parse(sessionStorage.getItem('capabilities'));
        let obj = _.find(capabilities, (o: screenCapabilitiesVO) => {
            return o.screenName == capability1;
        });
        if (obj.create && obj.update && obj.read && obj.delete) {
            return true;
        }
        return false;
    }
    public isFeatureAuthorized = (capability: string): boolean => {
        let boolean = false;
        let obj = _.find(this.sharedService.featureCapabilities, (o: FeatureRoleVO) => {
            return o.featureName == capability;
        });
        if (obj) {
            return obj.isEnabled;
        }
        return boolean;
    }
    public isMenuAvailable = (menu: string): boolean => {   
        if(this.sharedService.menuList.indexOf(menu) > -1){
            this.selectedMenuItem = menu;
        } else {
        }
        return this.sharedService.menuList.indexOf(menu) > -1;
    }

    public getSelectedMenuItem() {
        return this.selectedMenuItem;
    }
    public isMenuGroupAvailable = (menus: string[]) => {
        let boolean = false;
        menus.forEach(menu => {
            if (this.sharedService.menuList.indexOf(menu) > -1) {
                boolean = true;
            }
        });
        return boolean;
    }
    public isLoggedIn = (): boolean => {
        return Boolean(sessionStorage.getItem('access-token'))
    }
}
