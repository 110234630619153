import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-up-calendar',
  templateUrl: './up-calendar.component.html',
  styleUrls: ['./up-calendar.component.scss']
})
export class UpCalendarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  @Input()
  isRequired = false;
  @Input()
  dateSelection: string;
  @Input()
  isRange = false;
  @Output()
  dateChange = new EventEmitter<string>();
  public onDateChange = (event) => {
    if (this.dateSelection) {
      if (this.dateSelection !== event) {
        this.dateChange.emit(event.detail.dateSelection);
      }
    } else {
      this.dateChange.emit(event.detail.dateSelection);
    }
  }
}
