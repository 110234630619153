import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BoardTypeModel, changeHistoryMasterModel, DismissalReviewQueueMasterModel, EmployeeDetailsVO, EmployeeDetailsVOModel, EmployeePermBoardHistoryVOModel, LayoffCodesModel, RecalculatePointsRequestModel, RegionOrServiceUnitDetails, SearchIndividualHistoryProfileModel } from 'src/app/common/data/dataAPI/data-api.model';
import { Endpoints } from 'src/app/common/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private subject = new Subject<any>()
  public searchindividualHistoryUrl = '/secure/jas/services/individualHistory/searchHistory';
  public searchIndividualHistoryByEmployeeIdUrl = '/secure/jas/services/individualHistory/searchHistoryByEmployeeId';
  public changeHistoryUrl = '/secure/jas/services/individualHistory/changeHistory';
  public searchindividualHistoryPdfUrl = '/secure/jas/services/pdf/individualHistoryPdf';
  public recalculateindividualHistoryUrl = '/secure/jas/services/individualHistory/recaluclatePoints';
  public serviceUnitSearchUrl = '/secure/jas/services/districtOrSubDistrict/search';
  public districtSubDistrictSearchUrl = '/secure/jas/services/districtOrSubDistrict/search';
  public regionorServiceUnitPdfUrl = '/secure/jas/services/pdf/regionOrServiceUnitPdf';
  public districtSubDistrictSummaryPdfUrl = '/secure/jas/services/pdf/districtSubDistrictSummaryPdf';
  public getglobalProperties = '/secure/jas/services/applicationAttributes/getByType';
  public getglobalPropertiesByName = '/secure/jas/services/applicationAttributes/getByName';
  public getlayoffCodesUrl = '/secure/jas/services/statusCodes/byType';
  public getPermBoardHistoryUrl = '/secure/jas/services/employeeMaster/getPermBoardHistory';
  public individualHistoryPdfGenerationUrl = '/secure/jas/services/pdf/generateChangeHistoryPdf';
  public getAllLiteBoardTypesUrl = '/secure/jas/services/boardType/getAllLiteBoardTypes';
  public getregionorServiceUnitUrl = '/secure/jas/services/regionOrServiceUnit/search';
  public securityRegionTypeAheadsUrl = '/secure/jas/services/employee/getAllRegions';
  public securityDistinctServiceUnitTypeAheadsUrl = '/secure/jas/services/employee/getAllDistinctServiceUnits';
  public serviceUnitsforRegionsUrl = '/secure/jas/services/location/getServiceUnitsForRegions';
  public employeeTypeAheadsUrl = '/secure/jas/services/employee/getEmployeeNameAndId';
  POST_INDIVIDUAL_HISTORY_DATA = `post/individula-history-data`
  public cancelPauseDateUrl = '/secure/jas/services/individualHistory/cancelPausePeriod';


  constructor(private httpClient: HttpClient) { }

  public searchindividualHistory = (body: any): Observable<SearchIndividualHistoryProfileModel> => {
    return this.httpClient.post<any>(this.searchindividualHistoryUrl, body).pipe(map(response => {
      let searchindividualHistoryresponse: SearchIndividualHistoryProfileModel = new SearchIndividualHistoryProfileModel();
      searchindividualHistoryresponse.data = response.data;
      searchindividualHistoryresponse.result = response.result;
      searchindividualHistoryresponse.messages = response.messages;
      return searchindividualHistoryresponse;
    }))
  }

  public searchindividualHistoryNS = (employeeOid: number, startDate: string, endDate: string, selectedStatusCodes: any[], employeeId: string = null): Observable<SearchIndividualHistoryProfileModel> => {
    let endpoint = `${Endpoints.searchindividualHistory}?employeeOid=${employeeOid}&startDate=${startDate}&endDate=${endDate}&selectedStatusCodes=${selectedStatusCodes}`;
    if (employeeId != null) {
      endpoint = `${Endpoints.searchIndividualHistoryByEmployeeId}?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}&selectedStatusCodes=${selectedStatusCodes}`;
    }
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let searchindividualHistoryresponse: SearchIndividualHistoryProfileModel = new SearchIndividualHistoryProfileModel();
      searchindividualHistoryresponse.data = response.data;
      searchindividualHistoryresponse.result = response.result;
      searchindividualHistoryresponse.messages = response.messages;
      return searchindividualHistoryresponse;
    }))
  }

  public changeHistory = (employeeId: string): Observable<changeHistoryMasterModel> => {
    return this.httpClient.get<any>(this.changeHistoryUrl + "?employeeId=" + employeeId).pipe(map(response => {
      let changeHistoryResults: changeHistoryMasterModel = new changeHistoryMasterModel()
      changeHistoryResults.data = response.data;
      changeHistoryResults.messages = response.messages;
      changeHistoryResults.result = response.result;
      return changeHistoryResults;
    }));
  }
  public searchindividualHistoryPdf = (body: any): Observable<Blob> => {

    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
      //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.post<Blob>(this.searchindividualHistoryPdfUrl, body, httpOptions);
  }
  public recalculateindividualHistory = (payload: RecalculatePointsRequestModel): Observable<SearchIndividualHistoryProfileModel> => {
    return this.httpClient.post<any>(this.recalculateindividualHistoryUrl, payload).pipe(map(response => {
      let recalculateResults: SearchIndividualHistoryProfileModel = new SearchIndividualHistoryProfileModel();
      recalculateResults.data = response.data;
      recalculateResults.result = response.result;
      recalculateResults.messages = response.messages;
      return recalculateResults;
    }));
  }
  public serviceUnitSearch = (body: any) => {
    return this.httpClient.post<any>(this.serviceUnitSearchUrl, body).pipe(map(response => {
      let serviceunitregionsearchResults: RegionOrServiceUnitDetails = new RegionOrServiceUnitDetails;
      serviceunitregionsearchResults.data = response.data;
      serviceunitregionsearchResults.messages = response.messages;
      serviceunitregionsearchResults.result = response.result;
      return serviceunitregionsearchResults;
    }));
  }

  public districtSubdistrictSearch = (body: any) => {
    return this.httpClient.post<any>(this.districtSubDistrictSearchUrl, body).pipe(map(response => {
      let serviceunitregionsearchResults: RegionOrServiceUnitDetails = new RegionOrServiceUnitDetails;
      serviceunitregionsearchResults.data = response.data;
      serviceunitregionsearchResults.messages = response.messages;
      serviceunitregionsearchResults.result = response.result;
      return serviceunitregionsearchResults;
    }));
  }
  
  public serviceUnitRegionPdf = (regions: string[], serviceUnit: number, pointRangeList: any, boardTypeOIds: string[]): Observable<Blob> => {

    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
      //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(this.regionorServiceUnitPdfUrl, httpOptions);
  }
  public districtSubDistrictSummaryPdf = (body:any): Observable<Blob> => {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.httpClient.post<Blob>(this.districtSubDistrictSummaryPdfUrl, body, httpOptions);
  }
  public getReasons = () => {
    return this.httpClient.post<any>(this.getglobalProperties,{"type":"Include-Exclude Reason"}).pipe(map(response => {
      let getglobalPropertiesResults = response.data;
      return getglobalPropertiesResults;
    }))
  }
  public getReassignReasons = () => {
    return this.httpClient.post<any>(this.getglobalProperties,{"type":"Point Adjustments"}).pipe(map(response => {
      let getglobalPropertiesResults = response.data;
      return getglobalPropertiesResults;
    }))
  }

  public getOktaEnabled = () => {
	    return this.httpClient.post<any>(this.getglobalPropertiesByName,{"name":"Enable Okta Authentication"}).pipe(map(response => {
      let getglobalPropertiesResults:any = response.data;
      return getglobalPropertiesResults;
    }))
  }

  public getlayoffCodes = (): Observable<LayoffCodesModel[]> => {
    let endpoint = Endpoints.getlayoffCodes;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let layoffCodes: LayoffCodesModel[] = response.data;
      return layoffCodes;
    }))
  }


  public getPermBoardHistory = (employeeOid: number): Observable<EmployeePermBoardHistoryVOModel> => {

    return this.httpClient.get<any>(this.getPermBoardHistoryUrl).pipe(map(response => {
      let employeeDetails: EmployeePermBoardHistoryVOModel = new EmployeePermBoardHistoryVOModel;
      employeeDetails.data = response.data;
      employeeDetails.messages = response.messages;
      employeeDetails.result = response.result;
      return employeeDetails;
    }))
  }
  public individualHistoryPdf = (employeeId: string, showComments: boolean): Observable<Blob> => {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
      //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(this.individualHistoryPdfGenerationUrl + '?employeeId=' + employeeId + '&showComments=' + showComments, httpOptions);
  }
  public getBoardTypes = (): Observable<BoardTypeModel[]> => {
    return this.httpClient.get<any>(this.getAllLiteBoardTypesUrl).pipe(map(response => {
      let boardtypes: BoardTypeModel[] = response.data;
      return boardtypes;
    }))
  }

  public getserviceunitregion = (region: string[], serviceUnit: number): Observable<RegionOrServiceUnitDetails> => {
    return this.httpClient.get<any>(this.getregionorServiceUnitUrl).pipe(map(response => {
      let serviceunitregionsearchResults: RegionOrServiceUnitDetails = new RegionOrServiceUnitDetails;
      serviceunitregionsearchResults.data = response.data;
      serviceunitregionsearchResults.messages = response.messages;
      serviceunitregionsearchResults.result = response.result;
      return serviceunitregionsearchResults;
    }))
  }
  public securityRegionTypeAheads = (): Observable<any> => {
    return this.httpClient.get<any>(this.securityRegionTypeAheadsUrl).pipe(map(response => {
      let regiontypeaheadresponse: any = response.data;
      return regiontypeaheadresponse;
    }))
  }
  public securityDistinctServiceUnitTypeAheads = (): Observable<any> => {
    return this.httpClient.get<any>(this.securityDistinctServiceUnitTypeAheadsUrl).pipe(map(response => {
      let serviceunittypeaheadresponse: any = response.data;
      return serviceunittypeaheadresponse;
    }))
  }
  public serviceUnitsforRegions = (regionOids: any) => {
    return this.httpClient.get<any>(this.serviceUnitsforRegionsUrl).pipe(map(response => {
      let serviceUnitsforRegionsResults = response.data;
      return serviceUnitsforRegionsResults;
    }));
  }
  public employeeTypeAheads = (startingWith: string): Observable<any> => {
    return this.httpClient.get<any>(this.employeeTypeAheadsUrl).pipe(map(response => {
      let typeaheadresponse: any = response.data;
      return typeaheadresponse;
    }))
  }

  postIndividualHistoryData(body: any) {

    return this.httpClient.post(this.POST_INDIVIDUAL_HISTORY_DATA, body)
  }
  sendMessage(message: string) {
    this.subject.next({ value: message });
}
onMessage(): Observable<any> {
  return this.subject.asObservable();
}

public cancelPauseDate = (employeeOid: any, pauseDate:any): Observable<SearchIndividualHistoryProfileModel> => {
  let url = this.cancelPauseDateUrl + "/" + employeeOid
  if(pauseDate){
    url = url + "?pauseDate=" +pauseDate
  }
  return this.httpClient.get<any>(url).pipe(map(response => {
    let results: SearchIndividualHistoryProfileModel = new SearchIndividualHistoryProfileModel();
    results.data = response.data;
    results.result = response.result;
    results.messages = response.messages;
    return results;
  }));
}

public updateEmployeeTEYFlag = (employeeDetails: EmployeeDetailsVO): Observable<EmployeeDetailsVOModel> => {
  return this.httpClient.post<any>(Endpoints.updateEmployeeTEYFlag, employeeDetails).pipe(map(response => {
    let employeeDetails: EmployeeDetailsVOModel = new EmployeeDetailsVOModel   ;
    employeeDetails.data = response.data;
    employeeDetails.messages = response.messages;
    employeeDetails.result = response.result;
    return employeeDetails;
  }))
}





}
