import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddSecurityRolesComponent } from './dashboard/admin-control/security-profiles/add-security-roles/add-security-roles.component';
import { SecurityUsersAddComponent } from './dashboard/admin-control/security-users/security-users-add/security-users-add.component';
import { CreateAttendanceProfileComponent } from './dashboard/admin-control/attendance-profiles/create-attendance-profile/create-attendance-profile.component';
import { ManageAttendanceProfileComponent } from './dashboard/admin-control/attendance-profiles/manage-attendance-profile/manage-attendance-profile.component';
import { ApplicationAttributesFormComponent } from './dashboard/admin-control/application-attributes/application-attributes-form/application-attributes-form.component';
import { GlobalPropertiesFormComponent } from './dashboard/admin-control/global-properties/global-properties-form/global-properties-form.component';
import {JobControlComponent} from "./dashboard/admin-control/job-control/job-control.component";
import {AuthGuardService} from "./common/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public-pages/public-pages.module').then(m => m.PublicPagesModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./dashboard/home-screen/home-screen.module').then(m => m.HomeScreenModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'attendance',
    loadChildren: () => import('./dashboard/admin-control/attendance-profiles/attendance-profiles.module').then(m => m.AttendanceProfilesModule)
  },
  {
    path: 'create-attendance',
    component: CreateAttendanceProfileComponent
  },
  {
    path: 'create-attendance/:action/:oid',
    component: CreateAttendanceProfileComponent
  },
  {
    path: 'manageAttendanceProfile',
    component: ManageAttendanceProfileComponent
  },
  {
    path: 'application-attribute',
    loadChildren: () => import('./dashboard/admin-control/application-attributes/application-attributes.module').then(m => m.ApplicationAttributesModule)

  }, 
   {
    path:'attributes-add',
    component:ApplicationAttributesFormComponent
  },
  {
    path: "attributes-add/:action/:oid",
    component: ApplicationAttributesFormComponent
  },
  {
    path: 'gloabal-properties',
    loadChildren: () => import('./dashboard/admin-control/global-properties/global-properties.module').then(m => m.GlobalPropertiesModule)
  },
  {
    path: "properties-add",
    component: GlobalPropertiesFormComponent
  },
  {
    path: "properties-add/:action/:oid",
    component: GlobalPropertiesFormComponent
  },
  {
    path: "properties-add/:gtype",
    component: GlobalPropertiesFormComponent
  },
  {
    path: 'security-profiles',
    loadChildren: () => import('./dashboard/admin-control/security-profiles/security-profiles.module').then(m => m.SecurityProfilesModule)
  },
  {
    path: "add-security-roles/:action/:roleOid",
    component: AddSecurityRolesComponent
  },
  {
    path: "add-security-roles",
    component: AddSecurityRolesComponent
  },
  {
    path: 'security',
    loadChildren: () => import('./dashboard/admin-control/security-users/security-user.module').then(m => m.SecurityUsersModule)
  },
    {
    path: 'jobControl',
        loadChildren: () => import('./dashboard/admin-control/job-control/job-control.module').then(m => m.JobControlModule)
  },
  {
    path: "user/add",
    component: SecurityUsersAddComponent
 },
 {
   path:"user/add/:type/:action/:id",
   component:SecurityUsersAddComponent
 },
  {
    path: 'changeHistory',
    loadChildren: () => import('./dashboard/admin-control/change-history/change-history.module').then(m => m.ChangeHistoryModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'individualHistory',
    loadChildren: () => import('./dashboard/reports/individual-history/individual-history.module').then(m => m.IndividualHistoryModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'individualHistory/:employeeOid',
    loadChildren: () => import('./dashboard/reports/individual-history/individual-history.module').then(m => m.IndividualHistoryModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'districtSubdistrictSummary',
    loadChildren: () => import('./dashboard/reports/district-subdistrict-summary/district-subdistrict-summary.module').then(m => m.DistrictSubdistrictSummaryModule),
      canActivate: [AuthGuardService]

  },
  {
    path: 'districtSubdistrictSummary/:unit',
    loadChildren: () => import('./dashboard/reports/district-subdistrict-summary/district-subdistrict-summary.module').then(m => m.DistrictSubdistrictSummaryModule),
      canActivate : [AuthGuardService]
  },
  {
    path: 'disciplineReviewQueue',
    loadChildren: () => import('./dashboard/discipline-review-queue/discipline-review-queue.module').then(m => m.DisciplineReviewQueueModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'disputeQueue',
    loadChildren: () => import('./dashboard/dispute-queue/dispute-queue.module').then(m => m.DisputeQueueModule)
  },
  {
    path: 'lateToReport',
    loadChildren: () => import('./dashboard/late-to-report/late-to-report.module').then(m => m.LateToReportModule),
    canActivate: [AuthGuardService]
  }
  ,{
    path: 'notAuthorized',
    loadChildren: () => import('./dashboard/not-authorized/not-authorized-routing.module').then(m => m.NotAuthorizedRoutingModule)
  },
  {
    path: 'attendanceMonitor',
    loadChildren: () => import('./dashboard/attendance-monitor/attendance-monitor.module').then(m => m.AttendanceMonitorModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'attendanceMonitor/:employeeId',
    loadChildren: () => import('./dashboard/attendance-monitor/attendance-monitor.module').then(m => m.AttendanceMonitorModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'medical-queue',
    loadChildren: () => import('./dashboard/medical-queue/medical-queue.module').then(m => m.MedicalQueueModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'admincontrol',
    loadChildren: () => import('./dashboard/admin-control/admin-control.module').then(m => m.AdminControlModule),
      canActivate: [AuthGuardService]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
